import { Container } from "components/core";
import COLORS from "constants/colors";
import LEAVE from "constants/leave";
import { ChartData, LeaveCardData, FetchUsageStatsAndHolidayBalanceResponse, OverViewPopoverList } from "model/Leave";
import { Col, List, Popover, Modal, Row, Tooltip, Typography } from "antd";
import { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import Icon from "components/Icon";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import { useAuthContext } from "contexts";
import useFormModal from "hooks/useFormModal";
import { DefaultButton } from "components/Buttons";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { getChartData, getLeaveData } from "./modifyYourLeaveData";
import LeaveChart from "./LeaveChart";
import LeaveDetail from "./LeaveDetail";
import AdjustHolidayLeaveForm from "./AdjustHolidayLeaveForm";

type chartContainerProps = {
  title: string;
  data: ChartData;
  overViewListData?: OverViewPopoverList;
};

const ChartContainer = ({ title, data, overViewListData }: chartContainerProps) => (
  <>
    <StyledChartTitle>{title}</StyledChartTitle>
    <div className="d-flex justify-content-between align-items-center mt-2">
      <StyledSubTitle>
        <span>Total: {(data?.total ?? 0).toFixed(2)}</span>
        {data.name === "overview" && overViewListData && (
          <Popover
            title={
              <List
                size="small"
                dataSource={LEAVE.overViewPopoverListData(overViewListData)}
                renderItem={(item) => (
                  <StylePopoverList>
                    <StylePopoverListItem>
                      <span>{item.label}</span>
                      <span>{item.value} days</span>
                    </StylePopoverListItem>
                  </StylePopoverList>
                )}
              />
            }
            placement="rightTop"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          >
            <Icon name="infoOutline" />
          </Popover>
        )}
      </StyledSubTitle>
      <div className="d-flex align-items-center gap-4">
        <div className="d-flex align-items-center gap-2">
          <StyledSubTitle>Available: {(data?.available ?? 0).toFixed(2)}</StyledSubTitle>
          <Tooltip title="Total annual leave minus days already taken or booked">
            <Icon name="infoOutline" />
          </Tooltip>
        </div>
        {data.remaining && (
          <div className="d-flex align-items-center gap-2">
            <StyledSubTitle>Remaining: {(data?.remaining ?? 0).toFixed(2)}</StyledSubTitle>
            <Tooltip title="Total annual leave minus days already taken">
              <Icon name="infoOutline" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
    <LeaveChart data={[data]} />
  </>
);

const LeaveSummary = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;
  const [chartData, setChartData] = useState<ChartData[]>(LEAVE.chartData);
  const [leaveData, setLeaveData] = useState<LeaveCardData>();
  const [overViewData, setOverViewData] = useState<OverViewPopoverList>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isModalVisible, showModal, hideModal, onCloseModal, onFieldsChange } = useFormModal();
  const { employeeLeaveTab, adjustHolidayLeave } = PERMISSION;
  const { hasEmployeeLeaveTab, hasAdjustHolidayLeave } = useCheckPermission({ employeeLeaveTab, adjustHolidayLeave });
  const { state } = useLocation();

  useQuery<FetchUsageStatsAndHolidayBalanceResponse>(FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, {
    variables: { userId },
    onCompleted: (response) => {
      setIsLoading(true);
      setChartData(getChartData(response?.fetchHolidayBalance));
      setOverViewData({
        carryOverDays: response?.fetchHolidayBalance.carryOverDays,
        holidaysPerYear: response?.fetchHolidayBalance.holidaysPerYear,
        numberOfHolidayDayAdjustments: response?.fetchHolidayBalance.numberOfHolidayDayAdjustments,
      });
      setLeaveData(getLeaveData(response?.fetchUsageStats));
    },
  });
  useLayoutEffect(() => {
    setIsLoading(false);
  }, [chartData, leaveData]);

  const getLeaveCardSize = (leave: number) => {
    const leaveCardMap = {
      1: LEAVE.leaveCard1,
      2: LEAVE.leaveCard2,
      3: LEAVE.leaveCard3,
      4: LEAVE.leaveCard4,
      5: LEAVE.leaveCard56,
      6: LEAVE.leaveCard56,
      7: LEAVE.leaveCard78,
      8: LEAVE.leaveCard78,
      9: LEAVE.leaveCard910,
      10: LEAVE.leaveCard910,
    };
    return leaveCardMap[leave as keyof typeof leaveCardMap] || LEAVE.leaveCard1112;
  };

  return (
    <Row>
      <Col
        lg={leaveData && Object.keys(leaveData).length > 0 ? 13 : 24}
        xl={leaveData && Object.keys(leaveData).length > 0 ? 14 : 24}
        xxl={leaveData && Object.keys(leaveData).length > 0 ? 15 : 24}
        className="section-box-shadow"
      >
        {!isLoading && (
          <StyledInnerContainer>
            <div className="chartWrapper">
              <Typography.Title level={3} className="title">
                Holiday leave
              </Typography.Title>
              {id &&
                !state?.isLeaving &&
                (hasEmployeeLeaveTab || (hasAdjustHolidayLeave && user?.managedUserIds?.includes(id))) && (
                  <StyledAdjustDaysButton onClick={showModal}>Adjust days</StyledAdjustDaysButton>
                )}
              <div className="mt-4">
                <ChartContainer title="Full annual overview" data={chartData[0]} overViewListData={overViewData} />
              </div>
              <div>
                <ChartContainer title="Annual leave accrued" data={chartData[1]} />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-2 gap-5 gap">
              <StyledLegend className="taken">{(chartData[0]?.taken ?? 0).toFixed(2)} taken</StyledLegend>
              <StyledLegend className="booked">{(chartData[0]?.booked ?? 0).toFixed(2)} booked</StyledLegend>
              <StyledLegend className="requested">
                {(chartData[0]?.requested ?? 0).toFixed(2)} requested (not yet approved)
              </StyledLegend>
            </div>
          </StyledInnerContainer>
        )}
      </Col>
      {leaveData && Object.keys(leaveData).length > 0 && (
        <StyledLeaveDetailContainer lg={11} xl={10} xxl={9}>
          <LeaveDetail data={leaveData} cardSize={getLeaveCardSize(Object.keys(leaveData).length)} />
        </StyledLeaveDetailContainer>
      )}
      <Modal
        title="Adjust holiday leave days"
        open={isModalVisible}
        onCancel={onCloseModal}
        footer={null}
        width={340}
        destroyOnClose
        centered
      >
        <AdjustHolidayLeaveForm onFieldsChange={onFieldsChange} hideModal={hideModal} loading={false} />
      </Modal>
    </Row>
  );
};

export default LeaveSummary;
const StyledChartTitle = styled(Typography.Text)`
  color: ${COLORS.headerText};
  font-weight: 500;
`;

const StyledSubTitle = styled(StyledChartTitle)`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-popover {
    inset: 78.906px auto auto 129.609px !important;
    .ant-popover-arrow {
      top: 21px;
    }
  }
`;

const StyledLegend = styled.div`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
  &:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 6px;
  }
  &.taken:before {
    background: ${COLORS.takenBarColor};
  }
  &.booked:before {
    background: ${COLORS.bookedBarColor};
  }
  &.requested:before {
    background: ${COLORS.requestedBarColor};
  }
`;

const StyledInnerContainer = styled(Container)`
  &.pointer {
    cursor: pointer;
  }
  padding: 24px;
  position: relative;
`;

const StyledLeaveDetailContainer = styled(Col)`
  padding-left: 18px;
`;

const StylePopoverList = styled(List.Item)`
  &.ant-list-item {
    width: 307px;
    padding: 5px 12px;
    margin: 0;
  }
`;

const StyledAdjustDaysButton = styled(DefaultButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StylePopoverListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.white};
  width: 100%;
`;
