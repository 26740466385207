import { checkPermission } from "utils";
import { DefaultErrorSuccessResponse, ValidationErrors, WorkingCondition } from "./Common";
import { EmergencyContact, JobDetail, Label, PersonalDetails } from "./Employee";

export enum Admins {
  Superuser = "Superuser",
  Manager = "Manager",
}

export const isAdmin = (user?: User) =>
  checkPermission(
    user?.roles?.map((item) => item.name),
    [Admins.Superuser, Admins.Manager],
  );
export const isManager = (user?: User) =>
  checkPermission(
    user?.roles?.map((item) => item.name),
    [Admins.Manager],
  );

export type User = {
  id: string;
  name: string;
  email?: string;
  profileImageUrl?: string | null;
  startedAt?: string;
  needUpdateProfile?: boolean;
  roles?: userRole[];
  permissions?: string[];
  workingCondition?: WorkingCondition;
  departments?: Label[];
  jobTitle?: Label;
  location?: Label;
  lineManagers?: Label[];
  employeeNumber?: number | null;
  phone?: string | null;
  subscriptionActive?: boolean;
  managedUserIds?: string[];
  paymentVat?: number;
};

export type ChangePasswordPayload = {
  updateMe: {
    errors?: ValidationErrors;
    user: User;
  };
};

export type SignOutPayload = {
  user: User;
};

export type ResetPasswordPayload = {
  resetPassword: DefaultErrorSuccessResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type MutationResetPasswordArgs = {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
};

export type MutationLoginArgs = {
  email: string;
  password: string;
};

export type MutationForgotPasswordArgs = {
  email: string;
};

export type LoginMutation = {
  login: {
    token: string;
    user: User;
  } | null;
};

export type userRole = {
  id: string;
  name: string;
};

export type MeResponse = {
  me: {
    id: string;
    permissionsList: string[];
    personalDetail: PersonalDetails;
    emergencyContact: EmergencyContact;
    jobDetail: JobDetail | null;
    roles: userRole[];
    subscriptionActive: boolean;
    managedUserIds: string[];
    paymentVat: number;
  };
};

export type ForgotPasswordPayload = {
  forgotPassword: DefaultErrorSuccessResponse;
};

export type AcceptInviteArgs = {
  password: string;
  passwordConfirmation: string;
  invitationToken: string;
};

export type AcceptInvitePayload = {
  acceptInvite: DefaultErrorSuccessResponse;
};

export type SendReminderPayload = {
  sendReminder: DefaultErrorSuccessResponse;
};

export interface AcceptInviteResponse {
  acceptInvite: {
    errors: ValidationErrors;
    success: boolean;
    token: string;
  };
}

export enum HomeTabKeyEnum {
  Insights = "INSIGHTS",
  Leaves = "LEAVES",
  Documents = "DOCUMENTS",
}
