import { DatePicker, Form } from "antd";
import { DefaultButton, SuccessButton } from "components/Buttons";
import { Container } from "components/core";
import { InputDropDown, InputFile, InputText } from "components/Inputs";
import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";
import DATE_FORMAT from "constants/dateFormat";
import ALERTS, { fileSize } from "config/alerts";
import { enumValues } from "utils/misc";
import { useMutation } from "@apollo/client";
import { useNotify } from "services/api";
import { LeavingReasonType, LeavingRequestResponse, leavingReasonLabels } from "model/Leave";
import { CREATE_LEAVING_REQUEST, FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import { useState } from "react";
import { CALCULATE_DAYS_OFF } from "services/graphql/employee";
import { CalculateDayOffResponse } from "model/Employee";

type Props = {
  onFieldsChange: () => void;
  name?: string;
  id?: string;
  hideIsLeavingModal: () => void;
  employeeIsLeaving: () => void;
};

const IsLeavingForm = ({ onFieldsChange, name, id, hideIsLeavingModal, employeeIsLeaving }: Props) => {
  const [form] = useForm();
  const notify = useNotify();
  const [attachment, setAttachment] = useState<File | null>(null);

  const [dayOffCalculation, { loading: dayOffLoading }] = useMutation<CalculateDayOffResponse>(CALCULATE_DAYS_OFF, {
    onCompleted: (response) => {
      if (response?.calculateDaysOff) {
        form.setFieldValue("remainingDays", (response?.calculateDaysOff?.remainingDays ?? 0).toFixed(2));
      }
    },
  });

  const calculateDayOff = () => {
    const leavingDate = form.getFieldValue("leavingAt");
    if (leavingDate) {
      dayOffCalculation({ variables: { leavingAt: leavingDate, userId: id } });
    }
  };

  const [mutate, { loading }] = useMutation<LeavingRequestResponse>(CREATE_LEAVING_REQUEST, {
    onCompleted: (response) => {
      if (response?.createLeavingRequest) {
        if (response.createLeavingRequest.errors) {
          notify.error(response.createLeavingRequest.errors.fullMessages);
        } else {
          notify.success({ message: "Leaving request successfully." });
          hideIsLeavingModal();
          employeeIsLeaving();
        }
      }
    },
    refetchQueries: [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId: id } }],
  });

  return (
    <Container className="m-0 p-0">
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={onFieldsChange}
        initialValues={{ name }}
        onFinish={(values) => {
          const variables = values;
          ["name", "attachment"].map((fieldValue) => delete variables[fieldValue]);
          if (id && name)
            mutate({
              variables: {
                ...variables,
                userId: id,
                leavingAt: dayjs(variables.leavingAt).format(DATE_FORMAT.isoFormat),
                attachment,
              },
            });
        }}
      >
        <Form.Item label="Employee name" name="name">
          <InputText />
        </Form.Item>
        <Form.Item label="Reason for leaving" name="reason" rules={[ALERTS.required]}>
          <InputDropDown
            options={enumValues(LeavingReasonType).map((l) => ({
              label: leavingReasonLabels[l],
              value: l,
            }))}
            placeholder="Please select"
          />
        </Form.Item>
        <Form.Item label="Leaving date" name="leavingAt" rules={[ALERTS.required]}>
          <DatePicker
            className="w-100"
            showToday={false}
            changeOnBlur
            format={DATE_FORMAT.datePickerAllowDate}
            onChange={(date) => !date && form.setFieldValue("remainingDays", 0)}
          />
        </Form.Item>
        <div className="d-flex gap-2 align-items-center">
          <Form.Item label="Remaining days off" name="remainingDays" className="w-100">
            <InputText />
          </Form.Item>
          <DefaultButton onClick={calculateDayOff} loading={dayOffLoading} className="mt-2 w-100">
            Calculate dates off
          </DefaultButton>
        </div>
        <Form.Item label="Add attachment" name="attachment" rules={[fileSize(attachment)]}>
          <InputFile
            onChange={(e) => {
              if (e.target.files) {
                setAttachment(e.target.files[0]);
              }
            }}
          />
        </Form.Item>
        <SuccessButton block className="mt-3" htmlType="submit" loading={loading}>
          Save
        </SuccessButton>
      </Form>
    </Container>
  );
};

export default IsLeavingForm;
