import { useQueryParams, useSafeContext, useUpdater } from "hooks";
import {
  NotificationType,
  OtherNotificationMsg,
  messageItem,
  notificationsFilteredData,
  OtherUpdateData,
} from "model/Notification";
import { isAdmin, isManager } from "model/User";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { EnhancedLeaveRequest, FetchUserLeaveListResponse, LeaveRequestsStatus } from "model/Leave";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_LEAVE_REQUESTS_STATUS } from "services/graphql/leave";
import { expectNumber, includesInArray } from "utils";
import {
  BirthdaysThisWeek,
  ProbationaryPeriodEndingThisWeek,
  StartingThisWeek,
  TodayDrawerResponse,
  WorkAnniversaryThisWeek,
} from "model/Calendar";
import dayjs from "dayjs";
import { FETCH_TODAY_DRAWER_DATA } from "services/graphql/calendar";
import EMPLOYEE from "constants/employee";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import {
  DocumentActionDataModify,
  DocumentReviewDataType,
  DocumentReviewResponse,
  Documents,
  FetchDocumentsResponse,
} from "model/Document";
import { FETCH_DOCUMENTS, FETCH_DOCUMENT_REVIEW } from "services/graphql/document";
import { removeExtensionFromName } from "features/employee/leave";
import TABLE from "constants/table";
import { useAuthContext } from "./AuthProvider";

const NotificationContext = React.createContext<{
  notificationsCount: number;
  refresh: () => void;
  refetchEmployeePending: () => void;
  employeePendingData?: EnhancedLeaveRequest[];
  refetchMyNotificationLeave: () => void;
  myNotificationLeaveData?: EnhancedLeaveRequest[];
  otherNotificationData?: OtherUpdateData[];
  refetchOtherNotification: () => void;
  employeeNotificationCount: number;
  myNotificationCount: number;
  employeeActionedData: DocumentActionDataModify[];
  employeeDocumentRefetch: () => void;
  myDocumentData: DocumentReviewDataType[];
  myDocumentReviewRefetch: () => void;
  setOpenNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  openNotifications: boolean;
} | null>(null);

interface FetchDocumentDataArg {
  accumulated?: Documents[] | null;
  cursor?: string | null;
}
interface FetchPageDataArg extends Pick<FetchDocumentDataArg, "cursor"> {
  statuses: LeaveRequestsStatus[];
  accumulated?: EnhancedLeaveRequest[] | null;
  userId?: string;
}

export const useNotificationContext = () => useSafeContext(NotificationContext);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthContext();
  const [counter, updater] = useUpdater();
  const query = useQueryParams();

  const { companies, employeeDocumentNotification } = PERMISSION;
  const [openNotifications, setOpenNotifications] = useState<boolean>(query?.get("notification") === "true" || false);
  const [pendingLeaveData, setPendingLeaveData] = useState<EnhancedLeaveRequest[]>([]);
  const [approvedLeaveData, setApprovedLeaveData] = useState<EnhancedLeaveRequest[]>([]);
  const [employeeActionedData, setEmployeeActionedData] = useState<DocumentActionDataModify[]>([]);
  const { hasCompanies, hasEmployeeDocumentNotification } = useCheckPermission({
    companies,
    employeeDocumentNotification,
  });

  const [fetchUserLeaveList] = useLazyQuery<FetchUserLeaveListResponse>(FETCH_LEAVE_REQUESTS_STATUS, {
    fetchPolicy: "no-cache",
  });

  const fetchLeaveData = useCallback(
    async ({ statuses, cursor, accumulated, userId }: FetchPageDataArg) => {
      const result = await fetchUserLeaveList({
        variables: {
          first: TABLE.maxRowPerPage,
          after: cursor || undefined,
          statuses,
          userId: userId || undefined,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.leaveRequests;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchLeaveData({ accumulated: newData, statuses, cursor: pageInfo.endCursor, userId });
        }
        return newData;
      }
      return [];
    },
    [fetchUserLeaveList],
  );

  const refetchEmployeePending = useCallback(() => {
    if (!hasCompanies && user?.subscriptionActive && isAdmin(user)) {
      fetchLeaveData({
        statuses: [LeaveRequestsStatus.Pending],
      }).then((allFetchedData) => {
        const data = [...((allFetchedData as EnhancedLeaveRequest[]) ?? [])].sort(
          (a, b) => expectNumber(a.id) - expectNumber(b.id),
        );
        const sortData = isManager(user)
          ? data.filter((item) => includesInArray(user?.managedUserIds, item.user?.id))
          : data;
        setPendingLeaveData(sortData);
      });
    }
  }, [fetchLeaveData, hasCompanies, user]);

  const refetchMyNotificationLeave = useCallback(() => {
    if (!hasCompanies && user?.subscriptionActive && user?.id) {
      fetchLeaveData({
        statuses: [LeaveRequestsStatus.Approved, LeaveRequestsStatus.Declined],
        userId: user?.id,
      }).then((myNotificationLeaveData) => {
        setApprovedLeaveData(myNotificationLeaveData || []);
      });
    }
  }, [fetchLeaveData, hasCompanies, user]);

  const modifyOtherNotification = (response: TodayDrawerResponse) => {
    const otherData = Object.entries(response).reduce((result, [key, { nodes }]) => {
      if (nodes.length > 0) {
        result.push(
          ...nodes.map((item: messageItem, index: string) => ({
            id: expectNumber(`${item.id}${index}`),
            name: item.name,
            message: (EMPLOYEE.messages[key as keyof typeof EMPLOYEE.messages] as OtherNotificationMsg)(
              key === "birthdaysThisWeek"
                ? (item as BirthdaysThisWeek).dateOfBirth
                : (item as ProbationaryPeriodEndingThisWeek | StartingThisWeek | WorkAnniversaryThisWeek).startedAt,
            ),
          })),
        );
      }
      return result;
    }, [] as OtherUpdateData[]);

    return otherData;
  };

  const { data: otherNotification, refetch: refetchOtherNotification } = useQuery<TodayDrawerResponse>(
    FETCH_TODAY_DRAWER_DATA,
    {
      variables: {
        calendarYear: dayjs().year(),
        month: dayjs().month() + 1,
        day: dayjs().date(),
        skipPeopleOff: true,
      },
      skip: hasCompanies || !user?.subscriptionActive || !user?.id,
    },
  );

  const { data: myDocumentReviewData, refetch: myDocumentReviewRefetch } = useQuery<DocumentReviewResponse>(
    FETCH_DOCUMENT_REVIEW,
    {
      variables: { notActioned: true },
      skip: hasCompanies || !user?.subscriptionActive || !user?.id,
      fetchPolicy: "no-cache",
    },
  );

  const [fetchEmployeeDocument] = useLazyQuery<FetchDocumentsResponse>(FETCH_DOCUMENTS, {
    fetchPolicy: "no-cache",
  });

  const fetchDocumentData = useCallback(
    async ({ cursor, accumulated }: FetchDocumentDataArg) => {
      const result = await fetchEmployeeDocument({
        variables: {
          first: TABLE.maxRowPerPage,
          after: cursor || undefined,
          notCompleted: true,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.documents;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchDocumentData({ accumulated: newData, cursor: pageInfo.endCursor });
        }
        return newData;
      }
      return [];
    },
    [fetchEmployeeDocument],
  );

  const employeeDocumentRefetch = useCallback(() => {
    if (!hasCompanies && user?.subscriptionActive && hasEmployeeDocumentNotification) {
      fetchDocumentData({}).then((employeeDocumentActionedData) => {
        const data =
          employeeDocumentActionedData?.map((item) => ({
            id: item.id,
            name: removeExtensionFromName(item.attachmentName),
            actionPending: item.actionPendingCount,
            action: item.action?.description,
            deadlineDate: item.action?.deadlineAt,
          })) ?? ([] as DocumentActionDataModify[]);
        setEmployeeActionedData(data);
      });
    }
  }, [fetchDocumentData, hasCompanies, hasEmployeeDocumentNotification, user?.subscriptionActive]);

  const otherDataLength = otherNotification
    ? notificationsFilteredData(modifyOtherNotification(otherNotification), NotificationType.Other).length
    : 0;
  const pendingDataLength = pendingLeaveData?.length ?? 0;
  const approvedDataLength = notificationsFilteredData(approvedLeaveData, NotificationType.Holiday).length;

  const employeeDocumentLength = employeeActionedData
    ? notificationsFilteredData(employeeActionedData, NotificationType.Document).length
    : 0;

  const myDocumentLength = myDocumentReviewData
    ? notificationsFilteredData(myDocumentReviewData.documentReview.documents, NotificationType.MyDocument).length
    : 0;

  const adminNotificationsCount = isAdmin(user) ? pendingDataLength + employeeDocumentLength : 0;
  const notificationsCount =
    (isAdmin(user) ? otherDataLength : 0) + approvedDataLength + adminNotificationsCount + myDocumentLength;

  const employeeNotificationCount = otherDataLength + (isAdmin(user) ? pendingDataLength : 0) + employeeDocumentLength;
  const myNotificationCount = approvedDataLength + myDocumentLength;

  useLayoutEffect(() => {
    if (!pendingLeaveData?.length && !approvedLeaveData?.length && !employeeActionedData?.length) {
      refetchEmployeePending();
      refetchMyNotificationLeave();
      employeeDocumentRefetch();
    }
  }, [
    pendingLeaveData?.length,
    employeeActionedData.length,
    employeeDocumentRefetch,
    approvedLeaveData?.length,
    refetchEmployeePending,
    refetchMyNotificationLeave,
  ]);

  const value = useMemo(
    () => ({
      notificationsCount,
      refresh: updater,
      employeePendingData: pendingLeaveData,
      refetchEmployeePending,
      myNotificationLeaveData: notificationsFilteredData(approvedLeaveData, NotificationType.Holiday) ?? [],
      refetchMyNotificationLeave,
      otherNotificationData: otherNotification
        ? notificationsFilteredData(modifyOtherNotification(otherNotification), NotificationType.Other)
        : [],
      refetchOtherNotification,
      employeeNotificationCount,
      myNotificationCount,
      employeeActionedData: employeeActionedData
        ? notificationsFilteredData(employeeActionedData, NotificationType.Document)
        : [],
      employeeDocumentRefetch,
      myDocumentData: myDocumentReviewData
        ? notificationsFilteredData(myDocumentReviewData.documentReview.documents, NotificationType.MyDocument)
        : [],
      myDocumentReviewRefetch,
      setOpenNotifications,
      openNotifications,
    }),
    [
      notificationsCount,
      updater,
      pendingLeaveData,
      refetchEmployeePending,
      approvedLeaveData,
      refetchMyNotificationLeave,
      otherNotification,
      refetchOtherNotification,
      employeeNotificationCount,
      myNotificationCount,
      employeeActionedData,
      employeeDocumentRefetch,
      myDocumentReviewData,
      myDocumentReviewRefetch,
      openNotifications,
    ],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
