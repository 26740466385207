import { Space, Typography } from "antd";
import { Container } from "components/core";
import { InputDropDown } from "components/Inputs";
import COLORS from "constants/colors";
import { useDepartments, useLocations } from "hooks";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { FETCH_USER_COUNT_STATS } from "services/graphql/insights";
import dayjs from "dayjs";
import DATE_FORMAT from "constants/dateFormat";
import { useState } from "react";
import { DateRangePicker, FetchUserCountStatsResponse, LabelFilter } from "model/Insights";
import DateRangePickerFilter from "./DateRangePickerFilter";
import NoOfEmployeesChart from "./NoOfEmployeesChart";
import ChartSummary from "./ChartSummary";

const NumberOfEmployees = () => {
  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
  } = useDepartments({});
  const { locations, loading: locationsLoading, handleDropdownScroll: handleLocationDropdownScroll } = useLocations({});
  const [labelFilter, setLabelFilter] = useState<LabelFilter>();
  const [dateFilter, setDaterFilter] = useState<DateRangePicker>();
  const labelFilterOnChangeHandler = (value: string, key: "departments" | "locations") => {
    setLabelFilter((prev) => ({ ...prev, [key]: value === "all" ? undefined : value }));
  };

  const { data } = useQuery<FetchUserCountStatsResponse>(FETCH_USER_COUNT_STATS, {
    fetchPolicy: "no-cache",
    variables: {
      fromDate: (dateFilter?.from ? dayjs(dateFilter.from) : dayjs().subtract(1, "year")).format(
        DATE_FORMAT.isoFormatWithoutTime,
      ),
      toDate: (dateFilter?.to ? dayjs(dateFilter.to) : dayjs()).format(DATE_FORMAT.isoFormatWithoutTime),
      pastMonthDate: dayjs().subtract(1, "month").format(DATE_FORMAT.isoFormatWithoutTime),
      pastYearDate: dayjs().subtract(1, "year").format(DATE_FORMAT.isoFormatWithoutTime),
      departmentIds: labelFilter?.departments && [labelFilter?.departments],
      locationIds: labelFilter?.locations && [labelFilter?.locations],
    },
  });

  return (
    <StyledContainer data-testid="noOfEmployeeLineChart">
      <Space direction="vertical" size={[0, 7]} className="w-100">
        <StyledSectionTitle level={4}>Number of employees</StyledSectionTitle>
        <StyledFilterWrapper>
          <Space size={[7, 0]}>
            <StyledInputDropDown
              options={[{ value: "all", label: "All departments" }, ...departments]}
              loading={departmentsLoading}
              defaultValue="All departments"
              data-testid="departments"
              showSearch={false}
              optionLabelProp="label"
              onPopupScroll={handleDepartmentDropdownScroll}
              popupMatchSelectWidth={false}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => labelFilterOnChangeHandler(value, "departments")}
            />
            <StyledInputDropDown
              options={[{ value: "all", label: "All locations" }, ...locations]}
              loading={locationsLoading}
              defaultValue="All locations"
              data-testid="locations"
              showSearch={false}
              optionLabelProp="label"
              popupMatchSelectWidth={false}
              onPopupScroll={handleLocationDropdownScroll}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => labelFilterOnChangeHandler(value, "locations")}
            />
          </Space>
          <StyledDateWrapper>
            <Typography.Text>Show period:</Typography.Text>
            <DateRangePickerFilter
              dateFilter={dateFilter}
              onSubmit={(value: DateRangePicker) => setDaterFilter(value)}
            />
          </StyledDateWrapper>
        </StyledFilterWrapper>
        <div className="d-flex">
          <ChartSummary pastMonthData={data?.pastMonthCount} pastYearData={data?.pastYearCount} />
          <NoOfEmployeesChart data={data?.employeesCount} />
        </div>
      </Space>
    </StyledContainer>
  );
};

export default NumberOfEmployees;

const StyledContainer = styled(Container)`
  height: 100%;
  padding: 0px;
  box-shadow: 1px 1px 0px 0px ${COLORS.lightBoxShadowColor};
`;

const StyledSectionTitle = styled(Typography.Title)`
  &.ant-typography {
    margin: 0px;
    padding: 21px 25px 0px 20px;
  }
`;

const StyledInputDropDown = styled(InputDropDown)`
  &.ant-select.ant-select-single {
    height: 19px;

    & .ant-select-selector {
      border: none;
      padding: 0px 11px 0px 0px;

      & .ant-select-selection-search-input {
        height: 19px;
      }

      & .ant-select-selection-item {
        font-size: 13px;
        color: ${COLORS.disabledColor};
        line-height: 19px;
      }
    }
  }
`;

const StyledFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px 0px 20px;
`;

const StyledDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & .ant-typography {
    font-size: 13px;
    color: ${COLORS.disabledColor};
    font-weight: 500;
  }
  cursor: default;
`;
