import { Drawer, Tag, Typography, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import styled from "styled-components";
import { InputDropDown } from "components/Inputs";
import dayjs from "dayjs";
import { TodayDrawerFilter, TodayDrawerResponse } from "model/Calendar";
import COLORS from "constants/colors";
import { useDepartments, useLocations } from "hooks";
import DATE_FORMAT from "constants/dateFormat";
import { LoadingPage } from "components/core";
import { useQuery } from "@apollo/client";
import { FETCH_TODAY_DRAWER_DATA } from "services/graphql/calendar";
import { DrawerProps } from "antd/lib";

interface leaveDetailsProps {
  name: string;
  jobTitle: string;
  tagData: string;
}

const LeaveDetail = ({ name, jobTitle, tagData }: leaveDetailsProps) => (
  <div className="d-flex justify-content-between align-items-center py-3">
    <div className="d-flex flex-column border-bottom-0">
      <StyledText className="name-text">{name}</StyledText>
      <StyledText className="jobTitle-text">{jobTitle}</StyledText>
    </div>
    <StyledTag color={COLORS.tagBgColor}>{tagData}</StyledTag>
  </div>
);

const TodayDrawer = ({ open, onClose }: DrawerProps) => {
  const [form] = useForm();
  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
  } = useDepartments({});
  const { locations, loading: locationsLoading, handleDropdownScroll: handleLocationDropdownScroll } = useLocations({});
  const selectedDepartments: string[] | undefined = Form.useWatch("departments", form);
  const selectedLocations: string[] | undefined = Form.useWatch("locations", form);

  const { data, loading, refetch } = useQuery<TodayDrawerResponse>(FETCH_TODAY_DRAWER_DATA, {
    variables: {
      departmentIds: selectedDepartments,
      locationIds: selectedLocations,
      calendarYear: dayjs().year(),
      month: dayjs().month() + 1,
      day: dayjs().date(),
      skipPeopleOff: false,
    },
    fetchPolicy: "network-only",
    skip: !open,
  });

  return (
    <div>
      <StyledDrawer
        headerStyle={{ border: "none" }}
        title={
          <>
            <Typography.Title level={2}>What’s happening today?</Typography.Title>
            <StyledDateTitle level={3}>{dayjs().format(DATE_FORMAT.fullDateSecondary)}</StyledDateTitle>
          </>
        }
        onClose={onClose}
        width={507}
        open={open}
      >
        <Form
          layout="vertical"
          form={form}
          onValuesChange={(_, { departments, locations }: TodayDrawerFilter) =>
            refetch({
              calendarYear: dayjs().year(),
              month: dayjs().month() + 1,
              day: dayjs().date(),
              departmentIds: departments,
              locationIds: locations,
              skipPeopleOff: false,
            })
          }
        >
          <Form.Item label="Location" name="locations">
            <InputDropDown
              placeholder="Please select"
              options={locations}
              loading={locationsLoading}
              mode="multiple"
              className="w-100"
              maxTagCount="responsive"
              showSearch={false}
              selectedValue={selectedLocations}
              optionLabelProp="label"
              onPopupScroll={handleLocationDropdownScroll}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          <Form.Item label="Department" name="departments">
            <InputDropDown
              placeholder="Please select"
              options={departments}
              loading={departmentsLoading}
              mode="multiple"
              className="w-100"
              maxTagCount="responsive"
              showSearch={false}
              selectedValue={selectedDepartments}
              optionLabelProp="label"
              onPopupScroll={handleDepartmentDropdownScroll}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
        </Form>
        {!loading && data?.peopleOff.nodes.length !== 0 && (
          <div>
            <StyledTitle level={3}>People off</StyledTitle>
            {data?.peopleOff.nodes.map((detail) =>
              detail.leaveRequests.map((item, index) => (
                <StyledRowContainer key={`${detail.name + index}`}>
                  <LeaveDetail name={detail.name} jobTitle={detail.jobTitle?.name || "-"} tagData={item.reason} />
                </StyledRowContainer>
              )),
            )}
          </div>
        )}
        {!loading && data?.probationaryPeriodEndingThisWeek.nodes.length !== 0 && (
          <div>
            <StyledTitle level={3}>Probations ending</StyledTitle>
            {data?.probationaryPeriodEndingThisWeek.nodes.map((detail, index) => {
              const date = dayjs(detail.startedAt).set("year", dayjs().year()).subtract(1, "month");
              const difference = date.diff(dayjs(), "day");
              return (
                <StyledRowContainer key={`${detail.name + index}`}>
                  <LeaveDetail
                    name={detail.name}
                    jobTitle={detail.jobTitle?.name || "-"}
                    tagData={
                      date.isBefore(dayjs(), "day") && !date.isSame(dayjs(), "day")
                        ? `Ending ${Math.abs(difference)} days ago`
                        : date.isAfter(dayjs(), "day")
                        ? `Ending in ${Math.abs(difference) + 1} days`
                        : `Ending today`
                    }
                  />
                </StyledRowContainer>
              );
            })}
          </div>
        )}
        {!loading && data?.startingThisWeek.nodes.length !== 0 && (
          <div>
            <StyledTitle level={3}>People starting</StyledTitle>
            {data?.startingThisWeek.nodes.map((detail, index) => {
              const date = dayjs(detail.startedAt);
              const difference = date.diff(dayjs(), "day");
              return (
                <StyledRowContainer key={`${detail.name + index}`}>
                  <LeaveDetail
                    name={detail.name}
                    jobTitle={detail.jobTitle?.name || "-"}
                    tagData={
                      date.isBefore(dayjs(), "day") && !date.isSame(dayjs(), "day")
                        ? `Started ${Math.abs(difference)} days ago`
                        : date.isAfter(dayjs(), "day")
                        ? `Starting in ${Math.abs(difference) + 1} days`
                        : `Starting today`
                    }
                  />
                </StyledRowContainer>
              );
            })}
          </div>
        )}
        {!loading && data?.birthdaysThisWeek.nodes.length !== 0 && (
          <div>
            <StyledTitle level={3}>Birthdays this week</StyledTitle>
            {data?.birthdaysThisWeek.nodes.map((detail, index) => (
              <StyledRowContainer key={`${detail.name + index}`}>
                <LeaveDetail
                  name={detail.name}
                  jobTitle={detail.jobTitle?.name || "-"}
                  tagData={dayjs(detail.dateOfBirth).set("year", dayjs().year()).format(DATE_FORMAT.fullDateSecondary)}
                />
              </StyledRowContainer>
            ))}
          </div>
        )}
        {!loading && data?.workAnniversaryThisWeek.nodes.length !== 0 && (
          <div>
            <StyledTitle level={3}>Work anniversaries this week</StyledTitle>
            {data?.workAnniversaryThisWeek.nodes.map((detail, index) => (
              <StyledRowContainer key={`${detail.name + index}`}>
                <LeaveDetail
                  name={detail.name}
                  jobTitle={detail.jobTitle?.name || "-"}
                  tagData={dayjs(detail.startedAt).set("year", dayjs().year()).format(DATE_FORMAT.fullDateSecondary)}
                />
              </StyledRowContainer>
            ))}
          </div>
        )}
        {loading && <LoadingPage />}
      </StyledDrawer>
    </div>
  );
};

export default TodayDrawer;
const StyledRowContainer = styled.div`
  border-bottom: 1px solid ${COLORS.borderLight};
  &:last-child {
    border-bottom: none;
  }
`;

const StyledText = styled(Typography.Text)`
  &.ant-typography.name-text {
    font-weight: 500;
    color: ${COLORS.primaryColor};
  }
  &.ant-typography.jobTitle-text {
    font-size: 12px;
    color: ${COLORS.headerText};
  }
`;

const StyledTag = styled(Tag)`
  &.ant-tag.ant-tag-has-color {
    color: ${COLORS.tagColor};
    font-size: 12px;
    font-weight: 600;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 45px 35px;
    .ant-drawer-header-title {
      position: relative;
      button {
        position: absolute;
        right: 0;
        margin: 0;
        padding: 0;
        bottom: 45px;
      }
    }
  }
  .ant-drawer-body {
    padding: 12px 35px 35px 35px;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    margin: 0;
    padding: 45px 0px 12px 0px;
    border-bottom: 1px solid ${COLORS.borderLight};
    color: ${COLORS.headerTextBold};
  }
`;

const StyledDateTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${COLORS.headerText};
    margin: 0;
  }
`;
