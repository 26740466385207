import { List, Modal, Space, Typography } from "antd";
import { BaseButton, DefaultButton } from "components/Buttons";
import { Container } from "components/core";
import COLORS from "constants/colors";
import styled from "styled-components";
import { useModal } from "components/modal";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { Employee, FetchEmployeeResponse, FetchUserDataArg } from "model/Employee";
import { useCallback, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_USER } from "services/graphql/employee";
import TABLE from "constants/table";
import { NotLoggedInData, NotLoggedInUserDetails } from "model/Insights";
import useLoginReminder from "hooks/useLoginReminder";
import NotLoggedInSvg from "./NotLoggedInSvg";

const NotLoggedIn = () => {
  const { isModalVisible, hideModal, showModal } = useModal();
  const [data, setData] = useState<NotLoggedInData>();
  const [loadingState, setLoadingState] = useState<{ [x: string]: boolean }>({});
  const [refetch] = useLazyQuery<FetchEmployeeResponse>(FETCH_USER, {
    fetchPolicy: "no-cache",
  });

  const fetchUserData = useCallback(
    async ({ cursor, accumulated }: FetchUserDataArg) => {
      const result = await refetch({
        variables: {
          first: TABLE.rowsPerPage,
          after: cursor || undefined,
          fetchBirthdaysThisWeek: false,
          fetchWorkAnniversaryThisWeek: false,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.users;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchUserData({ accumulated: newData, cursor: pageInfo.endCursor });
        }
        return newData;
      }
      return [];
    },
    [refetch],
  );

  const modifyData = (userData: Employee[]) => {
    const { loggedInCount, notLoggedInCount, userDetails } = userData.reduce(
      (acc, user) => {
        if (user.loggedIn) {
          acc.loggedInCount += 1;
        } else {
          acc.notLoggedInCount += 1;
          acc.userDetails.push({ id: user.id, name: user.name, profileImageUrl: user.profileImageUrl });
        }
        return acc;
      },
      { loggedInCount: 0, notLoggedInCount: 0, userDetails: [] as NotLoggedInUserDetails[] },
    );
    const totalCount = userData.length;
    const loggedInRatio = totalCount ? parseFloat(((loggedInCount / totalCount) * 100).toFixed(2)) : 0;
    const notLoggedInRatio = totalCount ? parseFloat(((notLoggedInCount / totalCount) * 100).toFixed(2)) : 0;
    setData({
      notLoggedInCount,
      loggedInRatio,
      notLoggedInRatio,
      userDetails,
    });
  };

  useQuery<FetchEmployeeResponse>(FETCH_USER, {
    fetchPolicy: "no-cache",
    variables: {
      first: TABLE.rowsPerPage,
      fetchBirthdaysThisWeek: false,
      fetchWorkAnniversaryThisWeek: false,
    },
    onCompleted: (response) => {
      const { nodes, pageInfo } = response?.users;

      if (pageInfo?.hasNextPage) {
        fetchUserData({ accumulated: [...nodes], cursor: pageInfo.endCursor }).then((data) => modifyData(data));
      } else {
        modifyData([...nodes]);
      }
    },
  });

  const [reminderMutate] = useLoginReminder();

  const sendReminderHandler = async (id: string) => {
    setLoadingState((prev) => ({ ...prev, [id]: true }));
    reminderMutate({ variables: { userId: id } }).then(() => {
      setLoadingState((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    });
  };

  return (
    <StyledContainer data-testid="notLoggedIn">
      <StyledTitleWrapper>
        <div>
          <StyledTitle level={2}>{data?.notLoggedInCount ?? ""} Employees</StyledTitle>
          <StyledSubTitle>not yet logged in</StyledSubTitle>
        </div>
        <BaseButton onClick={showModal} disabled={!data}>
          View
        </BaseButton>
      </StyledTitleWrapper>
      <div className="d-flex justify-content-center align-items-center flex-column pt-4">
        <div>
          <NotLoggedInSvg notLoggedInUsers={data?.notLoggedInRatio ?? 0} />
        </div>
        <div className="me-4">
          <StyledLegend className="notLoggedIn">
            <Typography.Text>Not logged in</Typography.Text>
            <Typography.Title className="count" level={2}>
              {data?.notLoggedInRatio ?? 0}%
            </Typography.Title>
          </StyledLegend>
          <StyledLegend className="loggedIn">
            <Typography.Text>Logged in</Typography.Text>
            <Typography.Title className="count" level={2}>
              {data?.loggedInRatio ?? 0}%
            </Typography.Title>
          </StyledLegend>
        </div>
      </div>
      <StyledModal
        open={isModalVisible}
        onCancel={hideModal}
        footer={null}
        width={428}
        centered
        destroyOnClose
        rootClassName="secondary"
        title={<StyledModalTitle level={4}>Employees not yet logged in</StyledModalTitle>}
      >
        <List
          bordered
          dataSource={data?.userDetails ?? []}
          renderItem={(item) => (
            <StyledListItem>
              <Space size={[15, 0]}>
                <UserAvatar section="header" />
                <Typography.Text>{item.name}</Typography.Text>
              </Space>
              <DefaultButton
                loading={loadingState[item.id] || false}
                onClick={() => {
                  sendReminderHandler(item.id);
                }}
              >
                Send reminder
              </DefaultButton>
            </StyledListItem>
          )}
        />
      </StyledModal>
    </StyledContainer>
  );
};

export default NotLoggedIn;

const StyledContainer = styled(Container)`
  height: 100%;
  padding: 8px 8px 18px 8px;
  box-shadow: 1px 1px 0px 0px ${COLORS.lightBoxShadowColor};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background: ${COLORS.layoutBgColor};
  border-radius: 4px;
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 13px;
    color: ${COLORS.inputFieldTextColor};
    margin: 0px;
  }
`;

const StyledSubTitle = styled(Typography.Text)`
  &.ant-typography {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.inputFieldTextColor};
  }
`;

const StyledLegend = styled.div`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
  gap: 3px;
  &:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 6px;
  }
  &.loggedIn:before {
    background: ${COLORS.loggedInColor};
  }
  &.notLoggedIn:before {
    background: ${COLORS.notLoggedInColor};
  }
  & .ant-typography {
    color: ${COLORS.inputFieldTextColor};
    font-size: 12px;
  }
  & .ant-typography.count {
    margin: 0px;
  }
`;

const StyledListItem = styled(List.Item)`
  &.ant-list-item {
    padding: 6px 8px 6px 17px;
  }
`;

const StyledModalTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${COLORS.inputFieldTextColor};
    margin: 0;
    padding-top: 7px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-list ul.ant-list-items {
    max-height: 475px;
    overflow: auto;
  }
`;
