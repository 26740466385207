import { Col, DatePicker, Form, FormInstance, Image, InputNumber, Radio, Row, Typography } from "antd";
import { DeclineButton } from "components/Buttons";
import { InputFile, InputText } from "components/Inputs";
import ALERTS, { fileSize } from "config/alerts";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import useCheckPermission from "hooks/useCheckPermission";
import { Gender, genderLabel, PersonalDetailRef } from "model/Employee";
import { isAdmin, User } from "model/User";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { enumValues } from "utils/misc";

type Props = {
  id?: string;
  isMyProfile: boolean;
  setIsFieldsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
  user?: User;
};

const PersonalDetails = forwardRef<PersonalDetailRef, Props>(
  ({ id, isMyProfile, setIsFieldsChanged, form, user }, ref) => {
    const [profilePicture, setProfilePicture] = useState<File | null>();
    const [removeProfilePicture, setRemoveProfilePicture] = useState<boolean>(false);
    const { addProfileImage } = PERMISSION;
    const { hasAddProfileImage } = useCheckPermission({ addProfileImage });

    useImperativeHandle(ref, () => ({
      profilePicture,
      removeProfilePicture,
    }));

    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Typography.Title className="border-bottom pb-3 m-0" level={3}>
                Personal Details
              </Typography.Title>
            </Col>
          </Row>
          <Row className="mt-4" gutter={[38, 0]}>
            {id && (
              <Col md={24} lg={8} xl={7}>
                <Form.Item label="Employee number" name="employeeNumber">
                  <InputNumber className="w-100" controls={false} min={0} data-testid="employeeNumber" />
                </Form.Item>
              </Col>
            )}
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Name" name="name" rules={[ALERTS.required, ALERTS.characterLength]}>
                <InputText data-testid="name" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Gender" name="gender" className="text-center gender">
                <Radio.Group buttonStyle="solid" data-testid="gender">
                  {enumValues(Gender).map((l) => (
                    <Radio.Button value={l} key={l}>
                      {genderLabel[l]}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Personal phone" name="personalPhone">
                <InputText data-testid="personalPhone" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Personal email" name="personalEmail" rules={[ALERTS.email]}>
                <InputText type="email" data-testid="personalEmail" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Row>
                <Col span={24}>
                  <Form.Item label="Date of birth" name="dateOfBirth" className="m-0">
                    <DatePicker
                      className="w-100"
                      changeOnBlur
                      data-testid="dateOfBirth"
                      format={DATE_FORMAT.datePickerAllowDate}
                      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col md={24} lg={16} xl={14}>
              <Form.Item label="Address" name="address" className="mb-0">
                <InputText data-testid="address" />
              </Form.Item>
            </Col>
          </Row>
          {(isMyProfile || (isAdmin(user) && hasAddProfileImage)) && (
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <Typography.Title className="border-bottom py-3 mt-4 mb-0 profile-picture" level={4}>
                      Your “Profile picture”
                    </Typography.Title>
                  </Col>
                </Row>
                <Col md={24} lg={16} xl={18} className="mt-3">
                  <Form.Item
                    label="Upload image"
                    rules={[ALERTS.imageFile, fileSize(profilePicture)]}
                    className="profilePicture"
                    name="profilePicture"
                  >
                    <InputFile
                      className="w-100"
                      onChange={(e) => {
                        if (e.target.files) {
                          setProfilePicture(e.target.files[0]);
                          setIsFieldsChanged(!!e.target.files[0]);
                        }
                      }}
                    />
                  </Form.Item>
                  {profilePicture ? (
                    <StyledPreviewImage>
                      <Image className="rounded" src={URL.createObjectURL(profilePicture)} width={58} preview={false} />
                      <StyledDeclineButton
                        icon="remove"
                        onClick={() => {
                          setProfilePicture(null);
                          setIsFieldsChanged(false);
                          form.resetFields(["profilePicture"]);
                        }}
                      />
                    </StyledPreviewImage>
                  ) : (
                    form.getFieldValue("profileImageUrl") &&
                    !removeProfilePicture && (
                      <StyledPreviewImage>
                        <Image
                          className="rounded"
                          src={form.getFieldValue("profileImageUrl")}
                          width={58}
                          preview={false}
                        />
                        <StyledDeclineButton
                          icon="remove"
                          onClick={() => {
                            setRemoveProfilePicture(true);
                            setIsFieldsChanged(true);
                          }}
                        />
                      </StyledPreviewImage>
                    )
                  )}
                </Col>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  },
);

export default PersonalDetails;

const StyledPreviewImage = styled.div`
  position: relative;
  width: fit-content;
`;

const StyledDeclineButton = styled(DeclineButton)`
  &.ant-btn.ant-btn-icon-only {
    width: 18px;
    height: 18px;
    padding: 0px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -6px;
    svg {
      width: 8px;
      path {
        fill: ${COLORS.dangerColor};
      }
    }
  }
`;
