import COLORS from "constants/colors";
import styled from "styled-components";
import useWindowResize from "hooks/useWindowResize";
import { useDebounce } from "hooks";
import { useLayoutEffect, useState } from "react";

type Props = {
  dateNo: string;
  className: string;
  usedDays?: number;
};

type ExtraProps = {
  cellWidth: number;
};

const createConicGradient = (to: number, leaveColor: string, leaveLightColor: string) => {
  const cssString = `conic-gradient(${leaveColor} 0%, ${leaveColor} ${to * 100}%,
         ${leaveLightColor} ${to * 100}%, ${leaveLightColor} 0%)`;
  return cssString;
};

const CalendarData = ({ className, dateNo, usedDays }: Props) => {
  const { resized } = useWindowResize();
  const screenWidth = useDebounce(resized, 20);
  const [width, setWidth] = useState<number>(26);

  useLayoutEffect(() => {
    setWidth((document.querySelector(".date") as HTMLElement).getBoundingClientRect().width);
  }, [screenWidth]);

  return (
    <StyledSpan className={className} cellWidth={width} usedDays={usedDays}>
      {dateNo}
    </StyledSpan>
  );
};
export default CalendarData;

const StyledSpan = styled.span<Omit<Props & ExtraProps, "dateNo">>`
  width: ${(props) => (props?.cellWidth ? `${props.cellWidth}px` : "26px")};
  height: ${(props) => (props?.cellWidth ? `${props.cellWidth}px` : "26px")};
  cursor: default;
  color: ${COLORS.primaryColor};

  &.holiday {
    background-color: ${COLORS.holidayBgColor};
    box-shadow: 3px 2px 10px -1px ${COLORS.holidayBgColor};
    color: ${COLORS.white};
    font-weight: 500;
  }

  &.holiday.halfInAfternoon {
    background: linear-gradient(to left, ${COLORS.holidayBgColor} 50%, ${COLORS.holidayBgLightColor} 50%);
  }
  &.holiday.halfInMorning {
    background: linear-gradient(to left, ${COLORS.holidayBgLightColor} 50%, ${COLORS.holidayBgColor} 50%);
  }

  &.holiday.pending {
    border: 1.5px solid ${COLORS.holidayBgColor};
    border-left: none;
    border-right: none;
  }
  &.holiday.pending.start {
    border-left: 1.5px solid ${COLORS.holidayBgColor};
  }
  &.holiday.pending.end {
    border-right: 1.5px solid ${COLORS.holidayBgColor};
  }

  ${(props) =>
    props.usedDays
      ? `&.holiday.custom{ background-image: ${createConicGradient(
          props.usedDays,
          COLORS.holidayBgColor,
          COLORS.holidayBgLightColor,
        )};}`
      : ""};

  &.leave {
    background-color: ${COLORS.leaveBgColor};
    box-shadow: 3px 2px 10px -1px ${COLORS.leaveBgColor};
    color: ${COLORS.white};
    border-left: none;
    border-right: none;
    font-weight: 500;
  }

  &.leave.pending {
    border: 1.5px solid ${COLORS.leaveBgColor};
    border-left: none;
    border-right: none;
  }

  &.leave.pending.start {
    border-left: 1.5px solid ${COLORS.leaveBgColor};
  }
  &.leave.pending.end {
    border-right: 1.5px solid ${COLORS.leaveBgColor};
  }

  &.leave.halfInAfternoon {
    background: linear-gradient(to left, ${COLORS.leaveBgColor} 50%, ${COLORS.leaveBgLightColor} 50%);
  }
  &.leave.halfInMorning {
    background: linear-gradient(to left, ${COLORS.leaveBgLightColor} 50%, ${COLORS.leaveBgColor} 50%);
  }

  &.holiday.pending,
  &.leave.pending {
    background-color: transparent;
    box-shadow: none;
    color: ${COLORS.primaryColor};
  }

  ${(props) =>
    props.usedDays
      ? `&.leave.custom{ background-image: ${createConicGradient(
          props.usedDays,
          COLORS.leaveBgColor,
          COLORS.leaveBgLightColor,
        )};}`
      : ""};

  &.start {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  &.end {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  &.disabled {
    color: ${COLORS.dayOffDateColor};
    background: ${COLORS.dayOffDateBg};
  }

  &.today {
    font-weight: 800;
  }
`;
