import { Col, Row, TabsProps, Typography } from "antd";
import { BaseButton, SuccessButton } from "components/Buttons";
import { useRef, useState } from "react";
import FORM from "constants/form";
import { Tabs } from "components/Tabs";
import { ChangePasswordRef } from "model/Employee";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import styled from "styled-components";
import ChangePassword from "./ChangePassword";

export const UserSettings = () => {
  const [tabActiveKey, setTabActiveKey] = useState<string>("1");
  const changePasswordRef = useRef<ChangePasswordRef>(null);

  const { showConfirmModal } = usePreventUnsavedForm();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Password`,
      children: <ChangePassword ref={changePasswordRef} />,
    },
  ];

  const onCancelSetting = (key: string) => {
    if ( changePasswordRef.current?.isFieldsChanged) {
      showConfirmModal(() => {
        setTabActiveKey(key);
         if (changePasswordRef.current) {
          changePasswordRef.current?.resetForm();
          changePasswordRef.current?.setIsFieldsChanged(false);
        }
      });
    } else {
      setTabActiveKey(key);
    }
  };

  return (
    <div>
      <Row className="mb-4 pb-1" align="middle">
        <Col span={18}>
          <Typography.Title level={1} className="m-0">
            My Settings
          </Typography.Title>
        </Col>
        <Col span={6}>
          <div className="d-flex gap-3 justify-content-end">
            <BaseButton onClick={() => onCancelSetting(tabActiveKey)}>Cancel</BaseButton>
            <SuccessButton
              form={tabActiveKey === "1" ? FORM.changePasswordForm : FORM.notificationSettingForm}
              htmlType="submit"
            >
              Save
            </SuccessButton>
          </div>
        </Col>
      </Row>

      <StyledTab
        defaultActiveKey="1"
        items={items}
        activeKey={tabActiveKey}
        onTabClick={(activeKey) => {
          if (tabActiveKey !== activeKey) {
            onCancelSetting(activeKey);
          }
        }}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default UserSettings;

const StyledTab = styled(Tabs)`
  .ant-tabs-nav-list {
    margin-left: 4px;
  }
`;
