import { gql } from "@apollo/client";

export const FETCH_USER_COUNT_STATS = gql`
  query fetchUsersCountStats(
    $departmentIds: [ID!]
    $fromDate: ISO8601Date
    $locationIds: [ID!]
    $toDate: ISO8601Date
    $pastMonthDate: ISO8601Date
    $pastYearDate: ISO8601Date
  ) {
    employeesCount: fetchUsersCountStats(
      departmentIds: $departmentIds
      fromDate: $fromDate
      locationIds: $locationIds
      toDate: $toDate
    ) {
      monthYear
      totalUsers
      usersLeftInMonth
      usersStartedInMonth
    }

    pastMonthCount: fetchUsersCountStats(
      departmentIds: $departmentIds
      fromDate: $pastMonthDate
      locationIds: $locationIds
    ) {
      monthYear
      usersLeftInMonth
      usersStartedInMonth
    }

    pastYearCount: fetchUsersCountStats(
      departmentIds: $departmentIds
      fromDate: $pastYearDate
      locationIds: $locationIds
    ) {
      monthYear
      totalUsers
    }
  }
`;

export default {
  FETCH_USER_COUNT_STATS,
};
