import { useQuery } from "@apollo/client";
import { Modal, Typography } from "antd";
import { SuccessButton } from "components/Buttons";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import { getLeaveData } from "features/leave/modifyYourLeaveData";
import useCreateLeaveRequest from "hooks/useCreateLeaveRequest";
import useFormModal from "hooks/useFormModal";
import { LeaveCardData, LeaveRequestArg, FetchUsageStatsAndHolidayBalanceResponse, leaveTypeLabels } from "model/Leave";
import { useState } from "react";
import { FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import styled from "styled-components";
import LeaveForm from "./LeaveForm";

export const RequestLeave = () => {
  const { refetchList } = useFetchRequestList();
  const { showModal, hideModal, isModalVisible, onCloseModal, onFieldsChange } = useFormModal();
  const { user } = useAuthContext();
  const [leaveData, setLeaveData] = useState<LeaveCardData>();

  useQuery<FetchUsageStatsAndHolidayBalanceResponse>(FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, {
    variables: { userId: user?.id },
    onCompleted: (response) => {
      setLeaveData(getLeaveData(response?.fetchUsageStats));
    },
  });

  const { mutate, loading } = useCreateLeaveRequest(
    user?.id,
    () => {
      hideModal();
      onFieldsChange(false);
      refetchList(user?.id);
    },
    "request",
  );

  return (
    <StyledWrapper>
      {leaveData && Object.keys(leaveData).length > 0 && (
        <StyledList>
          {Object.keys(leaveData).map((item) => (
            <ul key={item} className="mb-1">
              <li>
                <div>{leaveTypeLabels[item as keyof LeaveCardData]} </div>
                <Typography.Text style={{ color: COLORS.headerText }}>
                  {`${
                    leaveData[item as keyof LeaveCardData]?.toFixed(
                      Number.isInteger(leaveData[item as keyof LeaveCardData]) ? 0 : 3,
                    ) || 0
                  } days`}
                </Typography.Text>
              </li>
            </ul>
          ))}
        </StyledList>
      )}
      <StyledSuccessButton onClick={showModal}>Request Leave</StyledSuccessButton>
      <Modal
        width={385}
        title="Request Leave"
        open={isModalVisible}
        footer={null}
        onCancel={onCloseModal}
        destroyOnClose
        centered
      >
        <LeaveForm
          leaveFormType="request"
          onFieldsChange={onFieldsChange}
          loading={loading}
          onSubmit={(values: LeaveRequestArg) => mutate({ variables: { ...values, userId: user?.id } })}
        />
      </Modal>
    </StyledWrapper>
  );
};

export default RequestLeave;

const StyledSuccessButton = styled(SuccessButton)`
  position: absolute;
  bottom: 22px;
  left: 20px;
  width: calc(100% - 40px);
`;

const StyledWrapper = styled.div`
  background-color: ${COLORS.lightActiveNavbar};
  border-radius: 4px;
  padding: 22px 20px;
  color: white;
  position: relative;
  min-height: 80px;
  max-height: 246px;
  margin-bottom: 22px;
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  flex-gap: 1rem;
  max-height: 152px;
  overflow-y: auto;
  margin-bottom: 4rem;
  ul {
    list-style-type: none;
    padding-inline-start: 0px;

    li {
      div {
        font-size: 13px;
      }
      .ant-typography {
        font-size: 12px;
      }
    }
  }
`;
