import { Typography } from "antd";
import Icon from "components/Icon";
import COLORS from "constants/colors";
import { PastMonthCount, PastYearCount } from "model/Insights";
import { useMemo } from "react";
import styled from "styled-components";

type Props = {
  pastMonthData?: PastMonthCount[];
  pastYearData?: PastYearCount[];
};
const ChartSummary = ({ pastMonthData, pastYearData }: Props) => {
  const { joiners, leavers } = useMemo(
    () =>
      (pastMonthData ?? []).reduce(
        (acc, curr) => ({
          joiners: acc.joiners + curr.usersStartedInMonth,
          leavers: acc.leavers + curr.usersLeftInMonth,
        }),
        {
          joiners: 0,
          leavers: 0,
        },
      ),
    [pastMonthData],
  );

  const { totalUsers, growthInPercentage, growth } = useMemo(() => {
    const currentTotal = pastYearData?.[pastYearData.length - 1]?.totalUsers ?? 0;
    const pastTotal = pastYearData?.[0]?.totalUsers ?? 0;
    const growth =
      !currentTotal && !pastTotal ? 0 : currentTotal && !pastTotal ? 100 : (currentTotal / pastTotal - 1) * 100;
    const rounded = Math.round(growth * 100) / 100;
    const growthInPercentage = rounded % 1 > 0 ? Math.abs(rounded).toFixed(2) : Math.abs(rounded);
    return {
      totalUsers: currentTotal,
      growthInPercentage,
      growth: growth > 0 ? 1 : 0,
    };
  }, [pastYearData]);

  return (
    <StyledWrapper>
      <StyledDescriptionWrapper>
        <StyledNumberTitle level={3} data-testid="totalUserCount">
          {totalUsers}
        </StyledNumberTitle>
        <StyledDetailsWrapper>
          <Typography.Title level={4} className="title">
            Total employees
          </Typography.Title>
          <Typography.Text className="details">
            <StyledUserGrowth className={`count ${growth > 0 ? "increase" : "decrease"}`} data-testid="userComparison">
              <Icon name="caretUpOutlined" />
              {growthInPercentage}%
            </StyledUserGrowth>
            compared with same date last year
          </Typography.Text>
        </StyledDetailsWrapper>
      </StyledDescriptionWrapper>
      <StyledDescriptionWrapper>
        <StyledNumberTitle level={3} data-testid="joinersCount">
          {joiners}
        </StyledNumberTitle>
        <StyledDetailsWrapper>
          <Typography.Title level={4} className="title">
            Joiners
          </Typography.Title>
          <Typography.Text className="details">in the last 30 days</Typography.Text>
        </StyledDetailsWrapper>
      </StyledDescriptionWrapper>
      <StyledDescriptionWrapper>
        <StyledNumberTitle level={3} data-testid="leaversCount">
          {leavers}
        </StyledNumberTitle>
        <StyledDetailsWrapper>
          <Typography.Title level={4} className="title">
            Leavers
          </Typography.Title>
          <Typography.Text className="details">in the last 30 days</Typography.Text>
        </StyledDetailsWrapper>
      </StyledDescriptionWrapper>
    </StyledWrapper>
  );
};

export default ChartSummary;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 13px 22px 0px 20px;
  margin-bottom: 36px;
  border-right: 1px solid ${COLORS.dividerBorderColor};
  width: 275px;
  min-width: 260px;
`;

const StyledNumberTitle = styled(Typography.Title)`
  &.ant-typography {
    width: 54px;
    height: 54px;
    border-radius: 8px;
    text-align: center;
    align-content: center;
    background-color: ${COLORS.layoutBgColor};
    margin: 0px;
    font-size: 18px;
    color: ${COLORS.inputFieldTextColor};
  }
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
`;

const StyledDetailsWrapper = styled.div`
  width: 61%;

  & .ant-typography.title {
    font-weight: 500;
    margin: 0px;
  }

  & .ant-typography.details {
    font-weight: 500;
    font-size: 12px;
  }
  & .count {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
  }
  & .count.increase {
    color: ${COLORS.successSecondaryColor};
  }
  & .count.decrease {
    color: ${COLORS.errorLightColor};
    & svg {
      transform: rotate(180deg);
    }
  }
`;

const StyledUserGrowth = styled.span``;
