import { useMutation } from "@apollo/client";
import TIME_FORMAT from "constants/timeFormat";
import { useAuthContext } from "contexts";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import dayjs from "dayjs";
import LeaveForm from "features/users/LeaveForm";
import {
  DayHalfTypes,
  DayTypes,
  LeaveRequestArg,
  LeaveRequestInitialValue,
  EnhancedLeaveRequest,
  ModifyLeaveRequestInitialData,
  DestroyLeaveRequestResponse,
  UpdateLeaveRequestResponse,
} from "model/Leave";
import { useParams } from "react-router-dom";
import { useNotify } from "services/api";
import {
  DESTROY_LEAVE_REQUEST,
  FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE,
  UPDATE_LEAVE_REQUEST,
} from "services/graphql/leave";
import { enumValues } from "utils/misc";

type Props = {
  onFieldsChange: (isChanged?: boolean) => void;
  hideModal: () => void;
  data?: EnhancedLeaveRequest;
  removeNotification?: () => void;
};

const CancelOrAmendForm = ({ onFieldsChange, data, hideModal, removeNotification }: Props) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const notify = useNotify();

  const { refetchList } = useFetchRequestList();
  const getInitialValue = (data?: EnhancedLeaveRequest) => {
    if (data) {
      const modifyData = {
        ...data,
        dayCompleteness: (enumValues(DayHalfTypes).includes(`${data.dayCompleteness}` as DayHalfTypes)
          ? DayTypes.Half
          : data.dayCompleteness) as DayTypes,
        dayHalfType: enumValues(DayHalfTypes).includes(`${data.dayCompleteness}` as DayHalfTypes)
          ? data.dayCompleteness
          : undefined,
        fromDate: dayjs(data.fromDate),
        fromTime: data.fromTime && dayjs(data.fromTime, TIME_FORMAT.timeFormatWith24Hour),
        toDate: dayjs(data.toDate),
        toTime: data.toTime && dayjs(data.toTime, TIME_FORMAT.timeFormatWith24Hour),
        employeeNote: data.employeeNote?.body,
        numberOfDays: data?.usedDays?.toFixed(Number.isInteger(data.usedDays) ? 0 : 3) || 0,
      };
      ["approvedAt", "declinedAt", "status"].map(
        (item) => delete modifyData[item as keyof ModifyLeaveRequestInitialData],
      );
      return { ...modifyData } as LeaveRequestInitialValue;
    }
    return undefined;
  };

  const [destroyLeaveRequest, { loading }] = useMutation<DestroyLeaveRequestResponse>(DESTROY_LEAVE_REQUEST, {
    onCompleted: (response) => {
      if (response?.destroyLeaveRequest?.success) {
        notify.success({ message: "Cancel leave request successfully." });
        hideModal();
        refetchList(id || user?.id);
        onFieldsChange(false);
        if (removeNotification) {
          removeNotification();
        }
      }
    },
    refetchQueries: [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId: id || user?.id } }],
  });

  const [updateLeaveRequest, { loading: updateRequest }] = useMutation<UpdateLeaveRequestResponse>(
    UPDATE_LEAVE_REQUEST,
    {
      onCompleted: (response) => {
        if (response?.updateLeaveRequest) {
          if (response.updateLeaveRequest.errors) {
            notify.error(response.updateLeaveRequest.errors.fullMessages);
          } else {
            notify.success({ message: "Leave request updated successfully." });
            hideModal();
            refetchList(id || user?.id);
            onFieldsChange(false);
            if (removeNotification) {
              removeNotification();
            }
          }
        }
      },
      refetchQueries: [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId: id || user?.id } }],
    },
  );

  return (
    <LeaveForm
      leaveFormType="request"
      onFieldsChange={onFieldsChange}
      initialValues={getInitialValue(data)}
      loading={loading || updateRequest}
      onSubmit={(value: LeaveRequestArg, btnClicked?: string) => {
        if (btnClicked === "delete") {
          return destroyLeaveRequest({ variables: { id: data?.id, notes: value.employeeNote } });
        }
        return updateLeaveRequest({ variables: { ...value, id: data?.id } });
      }}
    />
  );
};

export default CancelOrAmendForm;
