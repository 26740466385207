import { useQuery } from "@apollo/client";
import { Col, Form, FormInstance, Radio, Row, Tag, Typography } from "antd";
import { LoadingPage } from "components/core";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import STRIPE, { StripeCurrencySymbol } from "constants/stripe";
import dayjs from "dayjs";
import LeaveCardSvg from "features/leave/LeaveCardSvg";
import { BillingPackagesResponse, PricesIntervalEnum, PricesIntervalLabel } from "model/AdminSetting";
import { FETCH_BILLING_PACKAGES } from "services/graphql/adminSetting";
import styled from "styled-components";
import ALERTS from "config/alerts";
import React from "react";
import { useAuthContext } from "contexts";
import { IsMutationCall } from "./BillingForm";
import { ActionArg, Actions, StateData } from "./useBilling";

type Props = {
  form: FormInstance;
  setIsMutationCall: (value: IsMutationCall) => void;
  dispatch: React.Dispatch<ActionArg>;
  billingState: StateData;
  refetchPromoCode?: () => void;
};

const BillingPackageSection = ({ form, setIsMutationCall, billingState, dispatch, refetchPromoCode }: Props) => {
  const { user } = useAuthContext();

  const { data: packages, loading } = useQuery<BillingPackagesResponse>(FETCH_BILLING_PACKAGES, {
    onCompleted: (response) => {
      if (response.products.nodes.length) {
        dispatch({ type: Actions.PriceData, payload: response.products.nodes[0].prices });
        const interval = form.getFieldValue("interval");
        if (!interval) {
          form.setFieldsValue({
            interval: response.products.nodes[0].prices[0].id,
          });
        }
      }
    },
  });

  return (
    <StyledFirstSection>
      {!loading && packages?.products?.nodes.length ? (
        <div className="packages">
          <Col md={5} lg={4} xl={5}>
            <Form.Item label="YOUR PACKAGE" className="mb-0">
              <StyledSubTitle level={5}>{packages.products.nodes[0].name}</StyledSubTitle>
            </Form.Item>
          </Col>
          <Col md={5} lg={5} xl={6}>
            <Form.Item
              label="BILLING"
              name="interval"
              className="mb-0"
              shouldUpdate
              required={false}
              rules={[ALERTS.required]}
            >
              <Radio.Group
                data-testid="interval"
                onChange={() => {
                  setIsMutationCall({ package: true });
                  dispatch({ type: Actions.PromoCode, payload: undefined });
                  if (!billingState.defaultCardDetails && refetchPromoCode && billingState.promoCode) {
                    refetchPromoCode();
                  }
                }}
              >
                {packages.products.nodes[0].prices.map(({ interval, id }) => (
                  <StyledRadio value={id} key={interval}>
                    {PricesIntervalLabel[interval]}
                  </StyledRadio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col md={5} lg={8} xl={7}>
            <Form.Item label="PRICE" shouldUpdate className="mb-0">
              {({ getFieldValue }) => {
                const interval = getFieldValue("interval");
                const quantity = getFieldValue("quantity");
                if (interval && quantity) {
                  const priceData = packages.products.nodes[0].prices.filter((price) => price.id === interval)[0];
                  const price = quantity
                    ? (priceData.unitAmount / 100) *
                      quantity *
                      (billingState.promoCode?.coupon.percentOff
                        ? 1 - billingState.promoCode?.coupon.percentOff / 100
                        : 1)
                    : 0;
                  const totalPriceWithVat = price ? price * (user?.paymentVat ? user?.paymentVat / 100 : 1) + price : 0;

                  return (
                    <>
                      {user?.paymentVat && price && (
                        <StyledPriceWithoutVat>{`${
                          STRIPE.currencySymbols[priceData.currency as StripeCurrencySymbol]
                        }${price} + VAT(${user?.paymentVat}%)`}</StyledPriceWithoutVat>
                      )}
                      <StyledSubTitle level={5} className="d-flex align-items-center gap-2">
                        {totalPriceWithVat && priceData && (
                          <span>
                            {`Total: ${(totalPriceWithVat ?? 0).toFixed(2)} / 
                            ${priceData?.interval === PricesIntervalEnum.Month ? "month" : "year"}`}
                          </span>
                        )}
                        {billingState.promoCode?.coupon.percentOff && price && (
                          <StyledTag color={COLORS.discountBgColor}>
                            -{billingState.promoCode?.coupon.percentOff}% Off
                          </StyledTag>
                        )}
                      </StyledSubTitle>
                      {priceData.interval === PricesIntervalEnum.Year &&
                        billingState.promoCode?.coupon.percentOff &&
                        totalPriceWithVat && (
                          <span>{`${STRIPE.currencySymbols[priceData?.currency as StripeCurrencySymbol]}${(
                            totalPriceWithVat / 12
                          ).toFixed(2)} monthly`}</span>
                        )}
                    </>
                  );
                }
                return (
                  <>
                    <StyledSubTitle level={5} className="d-flex align-items-center gap-2">
                      <span>N/A</span>
                    </StyledSubTitle>
                    <StyledValidationSpan>Please add number of employees</StyledValidationSpan>
                  </>
                );
              }}
            </Form.Item>
          </Col>
          <Col md={5} lg={5} xl={5}>
            <Form.Item label="RENEWS ON" shouldUpdate className="mb-0">
              {({ getFieldValue }) => {
                const quantity = getFieldValue("quantity");
                const interval = getFieldValue("interval");
                const priceData = packages.products.nodes[0].prices.filter((price) => price.id === interval)[0];
                const isChangedInterval = billingState.currentPackage?.price.id === interval;
                return (
                  <StyledSubTitle level={5}>
                    {billingState.currentPackage?.currentPeriodEnd && isChangedInterval
                      ? dayjs(billingState.currentPackage.currentPeriodEnd).format(DATE_FORMAT.fullMonthAndYear)
                      : interval && quantity
                      ? dayjs()
                          .add(1, priceData.interval === PricesIntervalEnum.Month ? "month" : "year")
                          .format(DATE_FORMAT.fullMonthAndYear)
                      : "N/A"}
                  </StyledSubTitle>
                );
              }}
            </Form.Item>
          </Col>
        </div>
      ) : (
        <StyledLoadingPage>
          <LoadingPage />
        </StyledLoadingPage>
      )}
      <StyledCurveContainer>
        <LeaveCardSvg cardSize="1" type="SICK" />
      </StyledCurveContainer>
    </StyledFirstSection>
  );
};

export default BillingPackageSection;

const StyledFirstSection = styled(Row)`
  background: ${COLORS.billingFirstSectionBgColor};
  box-shadow: 10px 10px 40px 0px ${COLORS.billingFirstSectionShadowColor};
  padding: 32px 27px 24px 27px;
  border-radius: 4px;
  justify-content: space-between;
  height: 132px;
  z-index: 4;
  position: sticky;
  overflow: hidden;

  .ant-form-item .ant-form-item-label label {
    color: ${COLORS.billingTitleColor};
    font-size: 12px;
    margin: 0;
    font-weight: 600;
  }
`;

const StyledCurveContainer = styled.div`
  height: 150px;
  right: -5px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -10px;
  z-index: -1;
  overflow: hidden;
  opacity: 0.3;
  transform: rotate(2.8deg);
`;

const StyledRadio = styled(Radio)`
  & {
    font-size: 14px;
    margin: 0 24px 0 0;
    padding-top: 2px;
    color: ${COLORS.inputFieldTextColor};
    font-weight: 600;
  }
`;

const StyledTag = styled(Tag)`
  &.ant-tag {
    color: ${COLORS.discountColor};
  }
`;

const StyledLoadingPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & div {
    padding: 0 !important;
  }
`;

const StyledSubTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    margin: 0;
    margin-bottom: 0px;
    line-height: 22px;
  }
  &.danger {
    color: ${COLORS.dangerColor};
  }
`;

const StyledValidationSpan = styled.span`
  font-weight: 400;
  font-style: italic;
  color: ${COLORS.disabledColor};
  width: 510px;
  display: block;

  &.cardNotice {
    width: 100%;
    margin-bottom: 21px;
  }
`;

const StyledPriceWithoutVat = styled.span`
  color: ${COLORS.primaryColor};
`;
