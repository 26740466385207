export const PERMISSION = {
  createEmployee: ["employees:all", "employees:create"],
  destroyEmployee: ["employees:all", "employees:destroy"],
  companies: "companies:all",
  approveOrDeclineLeave: ["leave_requests:all", ["leave_requests:approve", "leave_requests:decline"]],
  recordLeave: "leave_requests:all",
  importEmployee: "employees:all",
  employeeReports: ["employees:all", "employees:export"],
  showEmployeeEditPage: [
    ["personal_details:all", "job_details:all", "emergency_contacts:all", "salaries:all"],
    ["personal_details:update", "personal_details:read"],
    ["job_details:upsert", "job_details:read"],
    ["emergency_contacts:upsert", "emergency_contacts:read"],
    ["salaries:upsert", "salaries:read"],
  ],
  updatePersonalDetails: [["personal_details:update", "personal_details:read"], "personal_details:all"],
  updateJobDetails: [["job_details:upsert", "job_details:read"], "job_details:all"],
  updateEmergencyDetails: [["emergency_contacts:upsert", "emergency_contacts:read"], "emergency_contacts:all"],
  updateSalaryDetails: [["salaries:upsert", "salaries:read"], "salaries:all"],
  personalDetailsView: ["personal_details:all", "personal_details:read"],
  employeeOverViewTab: [
    ["personal_details:all", "job_details:all", "emergency_contacts:all", "salaries:all"],
    "job_details:read",
    "emergency_contacts:read",
    "salaries:read",
    "personal_details:read",
  ],
  employeeLeaveTab: "employees:all",
  employeeLeaveOverview: ["employees:all", "employees:leave_overview"],
  employeeLeaveTabTable: ["employees:all", "employees:leave_overview"],
  employeeJobDetailView: ["job_details:all", "job_details:read"],
  employeeEmergencyContactsView: ["emergency_contacts:all", "emergency_contacts:read"],
  employeeSalaryView: ["salaries:all", "salaries:read"],
  showAdminSettingPage: [
    [
      "default_settings:all",
      "role_settings:all",
      "default_notification_settings:all",
      "job_titles:all",
      "departments:all",
      "locations:all",
    ],
    ["default_notification_settings:all", "job_titles:all", "departments:all", "locations:all"],
    ["billing:all"],
  ],
  showEmployeeDetailPage: [
    [
      "employees:all",
      "personal_details:all",
      "job_details:all",
      "emergency_contacts:all",
      "salaries:all",
      "leave_requests:all",
    ],
    ["personal_details:update", "personal_details:read"],
    ["job_details:upsert", "job_details:read"],
    ["emergency_contacts:upsert", "emergency_contacts:read"],
    ["salaries:upsert", "salaries:read"],
    "leaving_requests:create",
    "personal_details:read",
    "job_details:read",
    "emergency_contacts:read",
    "salaries:read",
    "leave_requests:all",
    "employees:leave_overview",
    "employees:read",
  ],
  defaultSettings: "default_settings:all",
  roleSettings: "role_settings:all",
  labels: ["job_titles:all", "departments:all", "locations:all"],
  defaultNotificationSettings: "default_notification_settings:all",
  deleteEmployeeDetail: ["employees:all", "employees:destroy"],
  rehireEmployeeDetail: ["employees:all", "employees:create"],
  showEmployeeList: ["employees:all", "employees:list"],
  createLeavingRequest: ["leaving_requests:all", "leaving_requests:create"],
  showDocumentsPage: "documents:all",
  documentTab: "documents:all",
  employeeDocumentNotification: "documents:all",
  billingTab: "billing:all",
  adjustHolidayLeave: "employees:leave_overview",
  addProfileImage: "employees:all",
  totalNumberOfEmployees: ["employees:all", "employees:list"],
};

export default PERMISSION;
