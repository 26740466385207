import { Col, Row } from "antd";
import LeaveSummary from "features/leave/LeaveSummary";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { useParams } from "react-router-dom";
import LeaveDetailTable from "./LeaveDetailTable";

const LeaveTab = () => {
  const { id } = useParams();
  const { employeeLeaveTabTable, employeeLeaveOverview } = PERMISSION;
  const { hasEmployeeLeaveTabTable, hasEmployeeLeaveOverview } = useCheckPermission({
    employeeLeaveTabTable,
    employeeLeaveOverview,
  });
  return (
    <div>
      {(hasEmployeeLeaveOverview || !id) && (
        <Row className="mb-4">
          <Col span={24}>
            <LeaveSummary />
          </Col>
        </Row>
      )}
      {(hasEmployeeLeaveTabTable || !id) && <LeaveDetailTable />}
    </div>
  );
};

export default LeaveTab;
