import { Dayjs } from "dayjs";
import { DefaultErrorSuccessResponse, PageInfo, ValidationErrors, WorkingCondition } from "./Common";
import { EmployeeId } from "./Employee";
import { User } from "./User";

interface Note {
  id: string;
  body: string;
}

export enum BookingStatus {
  Booked = "BOOKED",
  Taken = "TAKEN",
}

export const bookingStatusLabel = {
  [BookingStatus.Booked]: "Booked",
  [BookingStatus.Taken]: "Taken",
};

export interface LeaveRequestDateAndTimeDayjs {
  fromDate: Dayjs;
  fromTime: Dayjs;
  toDate: Dayjs;
  toTime: Dayjs;
}
export interface LeaveRequestDateAndTimeString {
  fromDate: string;
  fromTime: string;
  toDate: string;
  toTime: string;
}

export interface EnhancedLeaveRequest extends LeaveRequestDateAndTimeString {
  id: string;
  accumulativeBalance: number | null;
  approvedAt: string | null;
  attachmentUrl: string | null;
  dayCompleteness: LeaveRequestsDayCompleteness | null;
  declinedAt: string | null;
  employeeNote: Note | null;
  lineManagerNote: Note | null;
  precedingUsedHolidayDays: number | null;
  reason: LeaveRequestsReason;
  status: LeaveRequestsStatus;
  usedDays: number;
  user?: User;
  workingCondition: WorkingCondition;
  bookingStatus: BookingStatus | null;
}

export enum LeavingReasonType {
  Resigned = "RESIGNED",
  Dismissal = "DISMISSAL",
  NotPassProbation = "DID_NOT_PASS_PROBATION",
  EndOfContract = "END_OF_FIXED_TERM_CONTRACT",
  Redundancy = "REDUNDANCY",
  Other = "OTHER",
}

export const leavingReasonLabels = {
  [LeavingReasonType.Resigned]: "Resigned",
  [LeavingReasonType.Dismissal]: "Dismissal",
  [LeavingReasonType.NotPassProbation]: "Did not pass probation",
  [LeavingReasonType.EndOfContract]: "End of fixed term contract",
  [LeavingReasonType.Redundancy]: "Redundancy",
  [LeavingReasonType.Other]: "Other",
};

export enum AnnualLeaveEnum {
  AccruedLeaveOnly = "ACCRUED_LEAVE_ONLY",
  TotalAnnualAllowable = "TOTAL_ANNUAL_ALLOWABLE",
  AnyLeave = "ANY_LEAVE",
}

export const annualLeaveLabel = {
  [AnnualLeaveEnum.AccruedLeaveOnly]: {
    label: "Allow annual leave request for accrued leave only",
    popupTitle: "Employees can only request annual leave that has been accrued",
  },
  [AnnualLeaveEnum.TotalAnnualAllowable]: {
    label: "Allow annual leave request for total annual allowable",
    popupTitle: " Employees can request full annual leave at any time during the year",
  },
  [AnnualLeaveEnum.AnyLeave]: {
    label: "Allow any annual leave request",
    popupTitle: " Employees can request any amount of annual leave, it is up to their Manager to reject or accept",
  },
};

export interface LeavingRequest {
  id: string;
  leavingAt: string;
  reason: LeavingReasonType;
}

export interface LeavingRequestResponse {
  createLeavingRequest: { errors: ValidationErrors; leavingRequest: LeavingRequest };
}

export enum LeaveRequestsDayCompleteness {
  HalfInMorning = "HALF_DAY_MORNING",
  HalfInAfternoon = "HALF_DAY_AFTERNOON",
  Full = "FULL_DAY",
  Custom = "CUSTOM",
}

export enum DayTypes {
  Half = "HALF",
  Full = "FULL_DAY",
  Custom = "CUSTOM",
}

export enum DayHalfTypes {
  Morning = "HALF_DAY_MORNING",
  Afternoon = "HALF_DAY_AFTERNOON",
}

export const dayTypeLabels = {
  [DayTypes.Half]: "Half Day",
  [DayTypes.Full]: "Full Day",
  [DayTypes.Custom]: "Custom",
  [DayHalfTypes.Morning]: "Morning",
  [DayHalfTypes.Afternoon]: "Afternoon",
};

export enum LeaveFormType {
  Record = "record",
  Request = "request",
}

export enum LeaveRequestsReason {
  Holiday = "HOLIDAY",
  UnPaid = "UNPAID",
  Sick = "SICK",
  Maternity = "MATERNITY",
  Paternity = "PATERNITY",
  Adoptive = "ADOPTIVE",
  Parental = "PARENTAL",
  Carers = "CARERS",
  ForceMajeure = "FORCE_MAJEURE",
  Compassionate = "COMPASSIONATE",
  JuryService = "JURY_SERVICE",
  TimeInLieu = "TIME_IN_LIEU",
  Other = "OTHER",
}

export enum LeaveRequestsStatus {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export const leaveRequestsStatusLabels = {
  [LeaveRequestsStatus.Approved]: "Approved",
  [LeaveRequestsStatus.Declined]: "Declined",
  [LeaveRequestsStatus.Pending]: "Pending",
};

export const myLeaveRequestsStatusLabels = {
  [LeaveRequestsStatus.Approved]: "Accepted",
  [LeaveRequestsStatus.Declined]: "Declined",
  [LeaveRequestsStatus.Pending]: "Pending",
};

export const leaveTypeLabels: Record<LeaveRequestsReason, string> = {
  [LeaveRequestsReason.Holiday]: "Paid (holiday)",
  [LeaveRequestsReason.UnPaid]: "UnPaid",
  [LeaveRequestsReason.Sick]: "Sick Leave",
  [LeaveRequestsReason.Maternity]: "Maternity Leave",
  [LeaveRequestsReason.Paternity]: "Paternity Leave",
  [LeaveRequestsReason.Adoptive]: "Adoptive Leave",
  [LeaveRequestsReason.Parental]: "Parental Leave",
  [LeaveRequestsReason.Carers]: "Carers Leave",
  [LeaveRequestsReason.ForceMajeure]: "Force Majeure Leave",
  [LeaveRequestsReason.JuryService]: "Jury Service",
  [LeaveRequestsReason.Compassionate]: "Compassionate Leave",
  [LeaveRequestsReason.TimeInLieu]: "Time In Lieu",
  [LeaveRequestsReason.Other]: "Other",
};

export interface LeaveRequestArg {
  leaveRequestId?: string;
  dayCompleteness?: LeaveRequestsDayCompleteness | null;
  fromDate: string;
  fromTime: string;
  reason: LeaveRequestsReason;
  toDate: string;
  toTime: string;
  employeeNote: string;
  user?: string;
}

export interface LeaveRequest extends Pick<LeaveRequestArg, "reason" | "fromDate" | "fromTime" | "toDate" | "toTime"> {
  dayCompleteness: LeaveRequestsDayCompleteness;
  id: EmployeeId;
  status: LeaveRequestsStatus;
  workingCondition: WorkingCondition;
  employeeNote: Note;
  lineManagerNote: Note;
  attachmentUrl: string | null;
}
export interface LeaveRequestResponse {
  createLeaveRequest: {
    errors: ValidationErrors;
    leaveRequest: LeaveRequest;
  };
}

export interface LeaveRequestFormData extends Pick<EnhancedLeaveRequest, "reason">, LeaveRequestDateAndTimeDayjs {
  dayCompleteness: DayTypes;
  dayHalfType: DayHalfTypes;
  numberOfDays: number;
  employeeNote: string;
  user?: string;
  attachment?: string;
}

export interface ModifyLeaveRequestArg extends Omit<LeaveRequestArg, "leaveRequestId"> {
  dayHalfType?: DayHalfTypes;
}

export interface FetchUserLeaveListResponse {
  leaveRequests: {
    nodes: EnhancedLeaveRequest[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface ModifyLeaveRequestInitialData
  extends Pick<EnhancedLeaveRequest, "reason" | "dayCompleteness" | "id" | "usedDays">,
    LeaveRequestDateAndTimeDayjs {
  approvedAt?: string;
  declinedAt?: string;
  accumulativeBalance?: number;
  precedingUsedHolidayDays?: number;
  status?: LeaveRequestsStatus;
  workingCondition?: WorkingCondition;
  dayHalfType?: DayHalfTypes;
  employeeNote: string;
  numberOfDays: number;
}

export interface LeaveRequestInitialValue extends Omit<ModifyLeaveRequestInitialData, "dayCompleteness"> {
  dayCompleteness: DayTypes;
}

export interface UpdateLeaveRequestResponse {
  updateLeaveRequest: {
    errors: ValidationErrors;
    leaveRequest: LeaveRequest;
  };
}

export interface DestroyLeaveRequestResponse {
  destroyLeaveRequest: {
    success: boolean;
  };
}

export interface EstimateLeave {
  errors?: ValidationErrors;
  estimatedDays?: number;
  totalBalance?: number;
  usedDays?: number;
  usedDaysBeforeChange?: number;
}
export interface EstimateLeaveResponse {
  estimateLeaveRequest: EstimateLeave;
}

export interface LeaveBalanceCalculationArg {
  estimateData?: Omit<EstimateLeave, "errors">;
  initialValues?: Pick<ModifyLeaveRequestInitialData, "accumulativeBalance" | "precedingUsedHolidayDays" | "usedDays">;
  numberOfDays: number;
}

export interface leaveDataResponse {
  amount: number;
  reason: LeaveRequestsReason;
}

export interface FetchUsageStatsAndHolidayBalanceResponse {
  fetchUsageStats: leaveDataResponse[];
  fetchHolidayBalance: fetchHolidayBalance;
}

export interface fetchHolidayBalance {
  accruedBalance: number;
  accumulativeBalance: number;
  numberOfUsedDays: number;
  takenDays: number;
  bookedDays: number;
  carryOverDays?: number | null;
  holidaysPerYear: number;
  numberOfHolidayDayAdjustments: number;
  usedDaysStats: {
    amount: number;
    status: LeaveRequestsStatus;
  }[];
}

export interface ChartData {
  name: string;
  taken: number;
  booked: number;
  requested: number;
  total: number;
  remaining: number;
  available: number;
}

export interface LeaveCardData {
  [LeaveRequestsReason.Sick]: number;
  [LeaveRequestsReason.Carers]: number;
  [LeaveRequestsReason.Paternity]: number;
  [LeaveRequestsReason.JuryService]: number;
  [LeaveRequestsReason.Adoptive]: number;
  [LeaveRequestsReason.Compassionate]: number;
  [LeaveRequestsReason.Parental]: number;
  [LeaveRequestsReason.ForceMajeure]: number;
  [LeaveRequestsReason.UnPaid]: number;
  [LeaveRequestsReason.Maternity]: number;
  [LeaveRequestsReason.TimeInLieu]: number;
  [LeaveRequestsReason.Other]: number;
}

export interface DeclineLeaveRequestResponse {
  declineLeaveRequest: DefaultErrorSuccessResponse;
}

export interface ApproveLeaveRequestResponse {
  approveLeaveRequest: DefaultErrorSuccessResponse;
}

export interface ApproveLeaveRequestPayload {
  id: string;
  lineManagerNote?: string;
}

export type DeclineLeaveRequestPayload = ApproveLeaveRequestPayload;

export enum EditDayEnum {
  Add = "ADD",
  Remove = "REMOVE",
}
export const editDayLabels: Record<EditDayEnum, string> = {
  [EditDayEnum.Add]: "Add days",
  [EditDayEnum.Remove]: "Remove days",
};

export interface AdjustHolidayLeaveFormArg {
  addedAt: string;
  editType: EditDayEnum;
  amount: number;
  notes?: string;
}
export interface HolidayDayAdjustment {
  addedAt: string;
  amount: number;
  id: string;
  notes: string;
  reason: LeaveRequestsReason;
}
export interface CreateHolidayDayAdjustmentResponse {
  createHolidayDayAdjustment: {
    holidayDayAdjustment: HolidayDayAdjustment;
    errors?: ValidationErrors;
  };
}
export interface OverViewPopoverListData {
  label: string;
  value: string;
}

export interface OverViewPopoverList {
  carryOverDays?: number | null;
  holidaysPerYear?: number;
  numberOfHolidayDayAdjustments?: number;
}
