import COLORS from "constants/colors";
import React from "react";

type Props = {
  notLoggedInUsers: number;
};

const NotLoggedInSvg = ({ notLoggedInUsers }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="165" height="160" viewBox="0 0 100 100">
    {Array.from({ length: 10 }).map((_, rowIndex) =>
      Array.from({ length: 10 }).map((_, colIndex) => {
        const index = rowIndex * 10 + colIndex;
        let fillColor = COLORS.loggedInColor;
        if (index < Math.ceil(notLoggedInUsers)) {
          fillColor =
            index < Math.floor(notLoggedInUsers) ? COLORS.notLoggedInColor : `url(#partial-${rowIndex}-${colIndex})`;
        }
        return (
          <React.Fragment key={`circle-${rowIndex + 1}-${colIndex + 1}`}>
            <circle fill={fillColor} cx={5 + colIndex * 10} cy={5 + rowIndex * 10} r="2.8" />
            {index === Math.floor(notLoggedInUsers) && notLoggedInUsers % 1 !== 0 && (
              <defs>
                <linearGradient id={`partial-${rowIndex}-${colIndex}`} x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor={COLORS.notLoggedInColor} />
                  <stop offset={`${(notLoggedInUsers % 1) * 100}%`} stopColor={COLORS.notLoggedInColor} />
                  <stop offset={`${(notLoggedInUsers % 1) * 100}%`} stopColor={COLORS.loggedInColor} />
                  <stop offset="100%" stopColor={COLORS.loggedInColor} />
                </linearGradient>
              </defs>
            )}
          </React.Fragment>
        );
      }),
    )}
  </svg>
);

export default NotLoggedInSvg;
