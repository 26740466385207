import { Typography } from "antd";
import { Container } from "components/core";
import Icon from "components/Icon";
import { ICustomIconList } from "components/Icon/Icon";
import COLORS, { ColorType } from "constants/colors";
import LEAVE from "constants/leave";
import { leaveTypeLabels, LeaveRequestsReason } from "model/Leave";
import styled from "styled-components";
import lineCurve from "assets/images/leave-line-curve.svg";
import LeaveCardSvg from "./LeaveCardSvg";

type Props = {
  data: { [key in LeaveRequestsReason]?: number };
  cardSize: string;
};

type LeaveType = {
  type: string;
};
const LeaveDetail = ({ data, cardSize }: Props) => (
  <StyleWrapper className="d-grid" cardSize={cardSize}>
    {Object.keys(data).map((key) => (
      <StyledLeaveWrapper className="card" key={key} type={key} cardSize={cardSize}>
        <StyledRectangle cardSize={cardSize} type={key}>
          <LeaveCardSvg cardSize={cardSize} type={key} />
        </StyledRectangle>
        {(cardSize === LEAVE.leaveCard1 ||
          cardSize === LEAVE.leaveCard4 ||
          [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(cardSize)) && (
          <Icon name={key.toLocaleLowerCase() as ICustomIconList} />
        )}
        <StyledInfo cardSize={cardSize}>
          <Typography.Title level={4} className="title">
            {leaveTypeLabels[key as LeaveRequestsReason]}
          </Typography.Title>
          <Typography.Text className="text fw-light">
            {` ${
              data[key as LeaveRequestsReason]?.toFixed(Number.isInteger(data[key as LeaveRequestsReason]) ? 0 : 3) || 0
            } days`}
          </Typography.Text>
        </StyledInfo>
        {(cardSize === LEAVE.leaveCard1 ||
          cardSize === LEAVE.leaveCard4 ||
          [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(cardSize)) && <StyledLine />}
      </StyledLeaveWrapper>
    ))}
  </StyleWrapper>
);

export default LeaveDetail;
const StyleWrapper = styled.div<Omit<Props, "data">>`
  column-gap: ${(props) => LEAVE.leaveCardRowColumnGap[props.cardSize].col};
  row-gap: ${(props) => LEAVE.leaveCardRowColumnGap[props.cardSize].row};
  grid-template-columns: repeat(
    ${(props) =>
      [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(props.cardSize) || props.cardSize === LEAVE.leaveCard1 ? "1" : "2"},
    minmax(0, 1fr)
  );
  height: 100%;
`;

const StyledLeaveWrapper = styled(Container)<Omit<Props & LeaveType, "data">>`
  position: relative;
  background: ${(props) => COLORS[props.type.toLocaleLowerCase() as ColorType]};
  padding: ${(props) =>
    props.cardSize === LEAVE.leaveCard1
      ? "40px"
      : props.cardSize === LEAVE.leaveCard4
      ? "27px"
      : [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(props.cardSize)
      ? "18px"
      : "7px 1vw"};
  overflow: hidden;
  box-shadow: ${(props) =>
    props.cardSize === LEAVE.leaveCard4 || [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(props.cardSize)
      ? `8px 8px 20px 0px ${COLORS[`${props.type.toLocaleLowerCase()}Shadow` as ColorType]};`
      : `5px 8px 24px -2px ${COLORS[`${props.type.toLocaleLowerCase()}Shadow` as ColorType]};`};
  .anticon {
    margin-bottom: 21px;
  }
  ${(props) =>
    [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize) &&
    `
    display: flex;
    align-items: center;
  `}
  ${(props) =>
    [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(props.cardSize) &&
    `
    display: flex;
    align-items: center;
    .anticon {
      margin-top: -32px;
      margin-bottom: 0px;
    }
  `}
`;

const StyledRectangle = styled.div<Omit<Props & LeaveType, "data">>`
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  ${(props) =>
    props.cardSize === LEAVE.leaveCard1
      ? `right: 0; bottom:0; &,& svg {width:24vw; height:auto; max-width: 355px;}`
      : props.cardSize === LEAVE.leaveCard2
      ? `height: 110px; right: 0; bottom:0;`
      : props.cardSize === LEAVE.leaveCard3
      ? `height: 71px; right: 0; bottom:0;`
      : props.cardSize === LEAVE.leaveCard4
      ? `height: 100px; right: -10px; bottom:12px;`
      : props.cardSize === LEAVE.leaveCard56
      ? `height: 108px; right: 0; top:0;`
      : props.cardSize === LEAVE.leaveCard78
      ? `height: 109px; right: 0; top:0;`
      : props.cardSize === LEAVE.leaveCard910
      ? `height: 53px; top:1px; right: -3px; bottom:0;`
      : `top:0; right: -4px; bottom:0;`};
`;

const StyledLine = styled.div`
  position: absolute;
  right: 0;
  background-image: url(${lineCurve});
  width: 60px;
  height: 78px;
  top: -15px;
`;

const StyledInfo = styled.div<Omit<Props, "data">>`
  z-index: 99;
  position: absolute;
  line-height: ${(props) =>
    [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize)
      ? "unset"
      : "1.5"};

  ${(props) => [LEAVE.leaveCard2, LEAVE.leaveCard3].includes(props.cardSize) && `left:77px;`}
  .title {
    font-size: ${(props) =>
      [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize)
        ? "13px"
        : "15px"};
    line-height: ${(props) =>
      [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize)
        ? "16px"
        : "1.4"};
    margin-bottom: ${(props) =>
      [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize)
        ? "0px"
        : "0.5em"};
  }
  .text {
    color: ${COLORS.inputFieldTextColor};
    font-size: ${(props) =>
      [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize)
        ? "12px"
        : "14px"};
    line-height: ${(props) =>
      [LEAVE.leaveCard56, LEAVE.leaveCard78, LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(props.cardSize)
        ? "16px"
        : "1.4"};
  }
`;
