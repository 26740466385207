import { gql } from "@apollo/client";
import { EventType, Period } from "model/Calendar";
import { LeaveRequestsStatus } from "../../model/Leave";
import { PAGE_INFO_FRAGMENT, WORKING_CONDITION_FRAGMENT } from "./common";

export const FETCH_TODAY_DRAWER_DATA = gql`
  query fetchTodayDrawerData($departmentIds: [ID!], $locationIds: [ID!],
   $calendarYear: Int!, $month: Int!, $day: Int!,$skipPeopleOff: Boolean!) {
    peopleOff: users(
      filterBy: [{ eventType: ${EventType.IsOff}, period: ${Period.Day} }]
      departmentIds: $departmentIds
      locationIds: $locationIds   
    )  @skip(if: $skipPeopleOff) {
      nodes {
        id
        name
        jobTitle {
          name
        }
        leaveRequests(calendarYear: $calendarYear, month: $month, day: $day,
         statuses: [${LeaveRequestsStatus.Approved}]) {
          reason
          fromDate
          toDate
        }
      }
    }

    birthdaysThisWeek: users(
      filterBy: [{ eventType: ${EventType.Birthday}, period: ${Period.Week} }]
      departmentIds: $departmentIds
      locationIds: $locationIds
    ) {
      nodes {
        id
        name
        dateOfBirth
        jobTitle {
          name
        }
      }
    }

    workAnniversaryThisWeek: users(
      filterBy: [{ eventType: ${EventType.Anniversary}, period: ${Period.Week} }]
      departmentIds: $departmentIds
      locationIds: $locationIds
    ) {
      nodes {
        id
        name
        startedAt
        jobTitle {
          name
        }
      }
    }

    probationaryPeriodEndingThisWeek: users(
      filterBy: [{ eventType: ${EventType.Probationary}, period: ${Period.Week} }]
      departmentIds: $departmentIds
      locationIds: $locationIds
    ) {
      nodes {
        id
        name
        startedAt
        probationaryPeriod
        jobTitle {
          name
        }
      }
    }

    startingThisWeek: users(
      filterBy: [{ eventType: ${EventType.Starting}, period: ${Period.Week} }]
      departmentIds: $departmentIds
      locationIds: $locationIds
    ) {
      nodes {
        id
        name
        startedAt
        jobTitle {
          name
        }
      }
    }
  }
`;

export const FETCH_CALENDAR_DATA = gql`
  query fetchCalendarData(
    $first: Int
    $after: String
    $departmentIds: [ID!]
    $locationIds: [ID!]
    $calendarYear: Int!
    $month: Int!
    $statuses: [LeaveRequestsStatusEnum!]
    $showLeavers: Boolean
  ) {
    fetchCalendarData: users(
      first: $first
      after: $after
      departmentIds: $departmentIds
      locationIds: $locationIds
      showLeavers: $showLeavers
    ) {
      nodes {
        id
        name
        jobTitle {
          name
        }
        publicHolidays(calendarYear: $calendarYear) {
          date
          name
        }
        profileImageUrl
        leaveRequests(calendarYear: $calendarYear, month: $month, statuses: $statuses) {
          reason
          fromDate
          fromTime
          toTime
          toDate
          status
          dayCompleteness
          usedDays
          employeeNote {
            id
            body
          }
          workingCondition {
            ...WorkingConditionFragment
          }
        }
        workingCondition {
          ...WorkingConditionFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export default { FETCH_TODAY_DRAWER_DATA };
