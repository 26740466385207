import { useQuery } from "@apollo/client";
import { Col, Form, Image, Popover, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DeclineButton, PrimaryButton } from "components/Buttons";
import { InputFile } from "components/Inputs";
import { Container, LoadingPage } from "components/core";
import ALERTS, { fileSize } from "config/alerts";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import { useAuthContext } from "contexts";
import dayjs from "dayjs";
import useCheckPermission from "hooks/useCheckPermission";
import {
  EmployeeFullDetail,
  EmployeeId,
  EmployeeProfileFullDetail,
  ModifyEmployeeFullDetail,
  contractTypeLabel,
  genderLabel,
  paymentTypeLabel,
} from "model/Employee";
import { annualLeaveLabel } from "model/Leave";
import { isAdmin } from "model/User";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_EMPLOYEE_FULL_DETAIL, FETCH_PROFILE_FULL_DETAILS } from "services/graphql/employee";
import styled from "styled-components";
import { WorkingCondition, WorkingTimeEnum } from "model/Common";
import Icon from "components/Icon";
import { calculateHoursPerWeek, formatWorkingDay } from "utils";
import TIME_FORMAT from "constants/timeFormat";
import { WorkingConditionPayload } from "model/AdminSetting";
import EMPLOYEE from "constants/employee";
import WorkingConditionDetails from "./WorkingConditionDetails";
import useUploadProfileImage from "../useUploadProfileImage";

type EmployeeDataItemProps = {
  title: string;
  value: ReactNode;
  canUpdate?: boolean;
  labelClassName?: string;
  isComplianceField?: boolean;
  complianceWarningMsg?: string;
};

export const CompliancePopover = ({ complianceWarningMsg }: Pick<EmployeeDataItemProps, "complianceWarningMsg">) => (
  <Popover title={<StyledPopOverTitle>{complianceWarningMsg}</StyledPopOverTitle>} placement="top">
    <StyledIcon name="popover" />
  </Popover>
);

export const EmployeeDataItem = ({
  title,
  value,
  canUpdate,
  labelClassName,
  isComplianceField,
  complianceWarningMsg,
}: EmployeeDataItemProps) => {
  const { user } = useAuthContext();
  const canUpdateValue = canUpdate || isAdmin(user);
  return (
    <StyleListItem className="d-flex" data-testid="listItem">
      <StyledLabel className={labelClassName}>{title}</StyledLabel>
      <StyledData>
        {value ? (
          isComplianceField ? (
            <StyledComplianceContainer>
              <span>{value}</span>
              <CompliancePopover complianceWarningMsg={complianceWarningMsg} />
            </StyledComplianceContainer>
          ) : (
            value
          )
        ) : (
          <div>
            {canUpdateValue ? (
              <span className="message">Needs updating</span>
            ) : (
              <>
                <span className="message">Needs updating - Contact manager</span>
              </>
            )}
          </div>
        )}
      </StyledData>
    </StyleListItem>
  );
};

const OverviewTab = () => {
  const { user } = useAuthContext();
  const [profilePicture, setProfilePicture] = useState<File | null>();
  const {
    employeeJobDetailView,
    employeeEmergencyContactsView,
    employeeSalaryView,
    personalDetailsView,
    addProfileImage,
  } = PERMISSION;
  const {
    hasEmployeeJobDetailView,
    hasEmployeeEmergencyContactsView,
    hasEmployeeSalaryView,
    hasPersonalDetailsView,
    hasAddProfileImage,
  } = useCheckPermission({
    employeeJobDetailView,
    employeeEmergencyContactsView,
    employeeSalaryView,
    personalDetailsView,
    addProfileImage,
  });
  const [data, setData] = useState<ModifyEmployeeFullDetail>();
  const { id } = useParams();
  const userId = id || (user?.id as EmployeeId);
  const isMyProfile = userId === user?.id;
  const [form] = useForm();

  const { loading, refetch } = useQuery<EmployeeFullDetail | EmployeeProfileFullDetail>(
    id ? FETCH_EMPLOYEE_FULL_DETAIL : FETCH_PROFILE_FULL_DETAILS,
    {
      variables: id
        ? {
            userId,
            hasFetchPersonalDetail: !hasPersonalDetailsView && !!id,
            hasFetchJobDetail: !hasEmployeeJobDetailView && !!id,
            hasFetchEmergencyContact: !hasEmployeeEmergencyContactsView && !!id,
            hasFetchSalary: !hasEmployeeSalaryView && !!id,
          }
        : {},
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
      onCompleted: (response) => {
        if (response) {
          const responseData = id
            ? (response as EmployeeFullDetail)
            : (response as EmployeeProfileFullDetail)?.fetchProfileOverview;
          setData({ ...responseData });
        }
      },
    },
  );

  const { mutate, loading: uploading } = useUploadProfileImage();

  const hoursPerWeek = (workingCondition?: WorkingCondition) => {
    if (workingCondition) {
      if (workingCondition.workingTime === WorkingTimeEnum.Fixed) {
        return calculateHoursPerWeek({
          daysPerWeek: workingCondition?.workingDays.length,
          startTimeData: dayjs(workingCondition?.workingDays[0].startTime, TIME_FORMAT.timeFormatWith24Hour),
          endTimeData: dayjs(workingCondition?.workingDays[0].endTime, TIME_FORMAT.timeFormatWith24Hour),
          lunchBreak: workingCondition?.workingDays[0].lunchBreak,
        });
      }
      const modifyWorkingCondition = workingCondition.workingDays.reduce(
        (acc, day) => ({
          ...acc,
          [day.weekDay]: formatWorkingDay(day),
        }),
        {} as Record<string, WorkingConditionPayload>,
      );
      return calculateHoursPerWeek({ workingCondition: modifyWorkingCondition });
    }
    return 0;
  };

  return (
    <StyledContainer className="section-box-shadow">
      {loading && <LoadingPage />}
      <Row className="justify-content-between" gutter={40}>
        {(hasEmployeeJobDetailView || !id) && (
          <Col
            lg={data?.fetchJobDetail?.workingCondition?.workingTime === WorkingTimeEnum.Variable ? 14 : 12}
            xl={data?.fetchJobDetail?.workingCondition?.workingTime === WorkingTimeEnum.Variable ? 15 : 12}
            xxl={data?.fetchJobDetail?.workingCondition?.workingTime === WorkingTimeEnum.Variable ? 13 : 12}
          >
            <Row className="border-bottom mb-2">
              <StyledTitle level={1} className="title">
                Job Details
              </StyledTitle>
            </Row>
            <Row>
              <EmployeeDataItem title="Job Title" value={data?.fetchJobDetail?.jobTitle?.name} />
              <EmployeeDataItem
                title="Department"
                value={
                  data?.fetchJobDetail?.departments &&
                  data?.fetchJobDetail?.departments.map((department) => department.name).join()
                }
              />
              <EmployeeDataItem
                title="Line manager"
                value={
                  data?.fetchJobDetail?.lineManagers &&
                  data?.fetchJobDetail.lineManagers.map((lineManager) => lineManager.name).join()
                }
              />
              <EmployeeDataItem title="Work location" value={data?.fetchJobDetail?.location?.name} />
              <EmployeeDataItem
                title="Start day"
                value={
                  data?.fetchJobDetail?.startedAt &&
                  dayjs(data.fetchJobDetail.startedAt).format(DATE_FORMAT.fullDateDefault)
                }
              />
              <EmployeeDataItem
                title="End date (if applicable)"
                value={
                  (data?.fetchJobDetail?.endedAt &&
                    dayjs(data.fetchJobDetail.endedAt).format(DATE_FORMAT.fullDateDefault)) ||
                  "/"
                }
              />
              <EmployeeDataItem title="Probationary period (months)" value={data?.fetchJobDetail?.probationaryPeriod} />
              <EmployeeDataItem
                title="Annual leave"
                value={data?.fetchJobDetail?.annualLeave && annualLeaveLabel[data.fetchJobDetail.annualLeave].label}
              />
              <EmployeeDataItem
                title="Number of holidays per annum"
                value={data?.fetchJobDetail?.holidaysPerYear}
                isComplianceField={
                  !!id &&
                  isAdmin(user) &&
                  hoursPerWeek(data?.fetchJobDetail?.workingCondition) > 1365 / 52 &&
                  (data?.fetchJobDetail?.holidaysPerYear ?? 0) < 20
                }
                complianceWarningMsg={EMPLOYEE.complianceIssueMessage.holidaysPerAnnum}
              />
              <EmployeeDataItem
                title="Unused holidays allowed to be carried over year to year"
                value={data?.fetchJobDetail?.maxHolidaysFromLastYear || "/"}
              />
              <EmployeeDataItem
                title="Holidays brought forward from previous period"
                value={data?.fetchJobDetail?.accumulatedCarriedHolidays || "/"}
              />

              <WorkingConditionDetails
                workingCondition={data?.fetchJobDetail?.workingCondition}
                hoursPerWeek={hoursPerWeek(data?.fetchJobDetail?.workingCondition)}
              />
              <EmployeeDataItem
                title="Contract type"
                value={data?.fetchJobDetail?.contractType && contractTypeLabel[data.fetchJobDetail.contractType]}
              />
              <EmployeeDataItem title="Work phone number" value={data?.fetchJobDetail?.phone} />
              <EmployeeDataItem title="Work email" value={data?.fetchJobDetail?.email} />
            </Row>
          </Col>
        )}
        <Col lg={data?.fetchJobDetail?.workingCondition?.workingTime === WorkingTimeEnum.Variable ? 9 : 10}>
          {(hasPersonalDetailsView || !id) && (
            <Row>
              <Row className="border-bottom w-100 mb-2">
                <StyledTitle level={1} className="title">
                  Personal Details
                </StyledTitle>
              </Row>
              <Row>
                <EmployeeDataItem
                  title="Gender"
                  value={
                    data?.fetchPersonalDetail?.gender && genderLabel[data.fetchPersonalDetail.gender]?.split("")[0]
                  }
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Personal phone"
                  value={data?.fetchPersonalDetail?.personalPhone}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Personal email"
                  value={data?.fetchPersonalDetail?.personalEmail}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Date of birth"
                  value={
                    data?.fetchPersonalDetail?.dateOfBirth &&
                    dayjs(data?.fetchPersonalDetail?.dateOfBirth).format(DATE_FORMAT.fullDateDefault)
                  }
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Address"
                  value={data?.fetchPersonalDetail?.address}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
              </Row>
            </Row>
          )}
          {(hasEmployeeEmergencyContactsView || !id) && (
            <Row className="mt-5">
              <Row className="border-bottom w-100 mb-2">
                <StyledTitle level={1} className="title">
                  Emergency contact
                </StyledTitle>
              </Row>
              <Row>
                <EmployeeDataItem
                  title="Name"
                  value={data?.fetchEmergencyContact?.name}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Relationship"
                  value={data?.fetchEmergencyContact?.relationship}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Address"
                  value={data?.fetchEmergencyContact?.address}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Phone number"
                  value={data?.fetchEmergencyContact?.phone}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
                <EmployeeDataItem
                  title="Email"
                  value={data?.fetchEmergencyContact?.email}
                  canUpdate={!isAdmin(user)}
                  labelClassName="sm-label"
                />
              </Row>
            </Row>
          )}
          {(hasEmployeeSalaryView || !id) && (
            <Row className="mt-5">
              <Row className="border-bottom w-100 mb-2">
                <StyledTitle level={1} className="title">
                  Salary information
                </StyledTitle>
              </Row>
              <Row>
                <EmployeeDataItem title="Amount" value={data?.fetchSalary?.amount} labelClassName="sm-label" />
                <EmployeeDataItem title="Currency" value={data?.fetchSalary?.currency} labelClassName="sm-label" />
                <EmployeeDataItem
                  title="Type"
                  value={data?.fetchSalary?.paymentType && paymentTypeLabel[data.fetchSalary.paymentType]}
                  labelClassName="sm-label"
                />
              </Row>
            </Row>
          )}

          {(isMyProfile || (hasAddProfileImage && isAdmin(user))) && !data?.fetchPersonalDetail?.profileImageUrl && (
            <Row className="mt-5 w-100">
              <Row className="border-bottom w-100">
                <StyledTitle level={1} className="title">
                  Your “Profile picture”
                </StyledTitle>
              </Row>
              <Row className="mt-4 w-100">
                <Col span={18}>
                  <Form
                    form={form}
                    layout="vertical"
                    className="w-100"
                    onFinish={() => {
                      if (!profilePicture && !userId) return;

                      mutate({
                        variables: {
                          userId,
                          profileImage: profilePicture ?? null,
                        },
                      }).then(() => {
                        setProfilePicture(null);
                        refetch();
                      });
                    }}
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <Form.Item
                        label="Upload image"
                        name="profilePicture"
                        rules={[ALERTS.imageFile, fileSize(profilePicture)]}
                        className="profilePicture w-100"
                      >
                        <InputFile
                          className="w-100"
                          accept="image/*"
                          onChange={(e) => e.target.files && setProfilePicture(e.target.files[0])}
                        />
                      </Form.Item>
                      {profilePicture && (
                        <PrimaryButton
                          htmlType="submit"
                          className="profilePictureSubmit"
                          loading={uploading}
                          disabled={!profilePicture || uploading}
                        >
                          Upload image
                        </PrimaryButton>
                      )}
                    </div>
                    {profilePicture && (
                      <StyledPreviewImage>
                        <Image
                          className="rounded"
                          src={URL.createObjectURL(profilePicture)}
                          width={58}
                          preview={false}
                        />
                        <StyledDeclineButton
                          icon="remove"
                          onClick={() => {
                            setProfilePicture(null);
                            form.resetFields();
                          }}
                        />
                      </StyledPreviewImage>
                    )}
                  </Form>
                </Col>
              </Row>
            </Row>
          )}
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default OverviewTab;

const StyledContainer = styled(Container)`
  padding: 27px 36px;
  & .profilePicture .ant-form-item-explain.ant-form-item-explain-connected {
    margin-bottom: 15px;
  }
  & .profilePictureSubmit {
    margin-top: 10px;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.title {
    color: ${COLORS.headerText};
    font-weight: 600;
    font-size: 14px;
  }
`;

const StyleListItem = styled.span`
  width: 100%;
  padding: 8px 0;
  gap: 4%;
`;

const StyledLabel = styled.span`
  width: 350px;
  line-height: 26px;
  color: ${COLORS.inputFieldTextColor};
  &.sm-label {
    width: 200px;
  }
`;

const StyledData = styled(StyledLabel)`
  font-weight: 600;
  color: ${COLORS.primaryColor};
  width: 100%;

  span.message {
    padding: 2px 8px;
    background: ${COLORS.errorTextBgColor};
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    color: ${COLORS.errorTextColor};
    position: relative;
    display: inline-block;
  }
  span.popoverIcon {
    position: absolute;
    top: -8px;
    right: -10px;
  }
`;

const StyledDeclineButton = styled(DeclineButton)`
  &.ant-btn.ant-btn-icon-only {
    width: 18px;
    height: 18px;
    padding: 0px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -6px;
    svg {
      width: 8px;
      path {
        fill: ${COLORS.dangerColor};
      }
    }
  }
`;

const StyledPreviewImage = styled.div`
  position: relative;
  width: fit-content;
`;

const StyledComplianceContainer = styled.div`
  color: ${COLORS.hocColor};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(Icon)`
  svg rect {
    fill: ${COLORS.hocColor};
  }
`;

const StyledPopOverTitle = styled.div`
  width: 304px;
  display: block;
  text-align: center;
  line-height: 22px;
  padding: 10px 16px;
`;
