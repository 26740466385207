import { Col, Row, Typography } from "antd";
import Icon from "components/Icon";
import { Container } from "components/core";
import COLORS from "constants/colors";
import LEAVE from "constants/leave";
import LeaveCardSvg from "features/leave/LeaveCardSvg";
import styled from "styled-components";

const NoSubscription = () => (
  <StyledContainer>
    <Row className="d-flex align-items-center justify-content-between">
      <Col lg={16} xl={18}>
        <Row className="align-items-center gap-3 py-lg-3 py-xl-0">
          <Col lg={6} xl={3} className="subscription-icon">
            <Icon name="noSubscription" />
          </Col>
          <Col lg={17} xl={19} className="d-flex flex-column">
            <Typography.Title level={2}>No subscription</Typography.Title>
            <Typography.Text>
              Your subscription is not currently active, preventing access to our features.
            </Typography.Text>
            <Typography.Text>
              Once subscription is up-to-date you’ll be able to unlock the full potential of our app.
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <StyledCurve lg={8} xl={6}>
        <LeaveCardSvg cardSize={LEAVE.leaveCard4} type="subscription" />
      </StyledCurve>
    </Row>
  </StyledContainer>
);

export default NoSubscription;

const StyledContainer = styled(Container)`
  padding: 0 0 0 34px;
  background: ${COLORS.noSubscriptionBg};
  border: 1px solid ${COLORS.noSubscriptionBorder};

  .subscription-icon svg {
    width: 100%;
    height: auto;
  }
`;

const StyledCurve = styled(Col)`
  width: auto;
  height: auto;
  display: flex;
  justify-content: end;
  opacity: 40%;
  & svg {
    height: auto;
    width: 256px;
  }
`;
