import { useQuery } from "@apollo/client";
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from "antd";
import { InputAddDropDown, InputDropDown, InputText, SelectRefType } from "components/Inputs";
import ALERTS from "config/alerts";
import DATE_FORMAT from "constants/dateFormat";
import LABEL from "constants/label";
import WorkingConditionFormFields from "features/superAdmin/settings/DefaultSettings/WorkingConditionFormFields";
import { useDepartments, useJobTitles, useLocations } from "hooks";
import { VariableDailyTimeShowFieldReset, WorkingTimeEnum, workingTimeLabel } from "model/Common";
import {
  ContractType,
  contractTypeLabel,
  EmployeeInitialWorkingCondition,
  FetchEmployeeResponse,
} from "model/Employee";
import { AnnualLeaveEnum, annualLeaveLabel } from "model/Leave";
import { Admins } from "model/User";
import React, { useRef } from "react";
import { useNotify } from "services/api";
import { FETCH_LINE_MANAGERS } from "services/graphql/employee";
import { enumValues } from "utils/misc";

type Props = {
  form: FormInstance;
  id?: string;
  setIsFieldsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  hasUpdateJobDetails: boolean;
  initialWorkingCondition?: EmployeeInitialWorkingCondition;
};

const JobDetails = ({ form, id, setIsFieldsChanged, hasUpdateJobDetails, initialWorkingCondition }: Props) => {
  const notify = useNotify();
  const departmentSelectRef = useRef<SelectRefType>(null);
  const locationSelectRef = useRef<SelectRefType>(null);
  const jobTitleSelectRef = useRef<SelectRefType>(null);
  const workingConditionRef = useRef<VariableDailyTimeShowFieldReset>(null);
  const workingTime = Form.useWatch("workingTime", form);
  const selectedDepartment = Form.useWatch("departmentIds", form);
  const selectedLineManager = Form.useWatch("lineManagerIds", form);
  const selectedJobTitle = Form.useWatch("jobTitleId", form);

  const {
    jobTitles,
    loading: jobTitlesLoading,
    handleDropdownScroll: handleJobTitleDropdownScroll,
    createJobTitle,
  } = useJobTitles({
    skip: !id,
    notify,
    after: true,
    onCompleted: (response) => {
      form.setFieldValue("jobTitleId", response.createJobTitle.jobTitle.id);
      jobTitleSelectRef.current?.onAddSuccessFully();
      setIsFieldsChanged(true);
    },
  });

  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
    createDepartment,
  } = useDepartments({
    skip: !id,
    notify,
    after: true,
    onCompleted: (response) => {
      if (selectedDepartment && selectedDepartment.length) {
        form.setFieldValue("departmentIds", [...selectedDepartment, response.createDepartment.department.id]);
      } else {
        form.setFieldValue("departmentIds", [response.createDepartment.department.id]);
      }
      form.validateFields(["departmentIds"]);
      departmentSelectRef.current?.onAddSuccessFully();
      setIsFieldsChanged(true);
    },
  });

  const {
    locations,
    loading: locationsLoading,
    handleDropdownScroll: handleLocationDropdownScroll,
    createLocation,
  } = useLocations({
    skip: !id,
    notify,
    after: true,
    onCompleted: (response) => {
      form.setFieldValue("locationId", response.createLocation.location.id);
      locationSelectRef.current?.onAddSuccessFully();
      setIsFieldsChanged(true);
    },
  });

  const { data: lineManagers, loading: lineManagersLoading } = useQuery<FetchEmployeeResponse>(FETCH_LINE_MANAGERS, {
    variables: { first: LABEL.rowsPerPage, roles: [Admins.Superuser.toUpperCase(), Admins.Manager.toUpperCase()] },
    fetchPolicy: "no-cache",
    skip: !hasUpdateJobDetails || !id,
  });

  const handleWorkingTimeChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({
      workingCondition: undefined,
      hoursPerWeek: undefined,
      startTime: undefined,
      endTime: undefined,
      daysPerWeek: undefined,
    });
    workingConditionRef?.current?.variableDailyTimeShowFieldReset();
    if (initialWorkingCondition?.workingTime === WorkingTimeEnum.Fixed && e.target.value === WorkingTimeEnum.Fixed) {
      form.setFieldsValue({
        startTime: initialWorkingCondition?.startTime,
        endTime: initialWorkingCondition?.endTime,
        daysPerWeek: initialWorkingCondition?.daysPerWeek,
        hoursPerWeek: initialWorkingCondition?.hoursPerWeek,
      });
    } else if (
      initialWorkingCondition?.workingTime === WorkingTimeEnum.Variable &&
      e.target.value === WorkingTimeEnum.Variable
    ) {
      form.setFieldsValue({
        workingCondition: initialWorkingCondition?.workingCondition,
        hoursPerWeek: initialWorkingCondition?.hoursPerWeek,
      });
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Typography.Title className="border-bottom pb-3 mt-5 mb-0" level={3}>
                Job details
              </Typography.Title>
            </Col>
          </Row>
          <Row className="mt-4" gutter={[38, 0]}>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Job title" name="jobTitleId">
                <InputAddDropDown
                  options={jobTitles}
                  data-testid="jobTitleId"
                  placeholder="Please select"
                  loading={jobTitlesLoading}
                  onPopupScroll={handleJobTitleDropdownScroll}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  addBtnFor="job title"
                  onAdd={(data) => createJobTitle({ name: data.newLabel })}
                  ref={jobTitleSelectRef}
                  selectedValue={selectedJobTitle}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Department" name="departmentIds">
                <InputAddDropDown
                  options={departments}
                  loading={departmentsLoading}
                  placeholder="Please select"
                  optionLabelProp="label"
                  showSearch={false}
                  maxTagCount="responsive"
                  data-testid="departmentIds"
                  mode="multiple"
                  selectedValue={selectedDepartment}
                  onPopupScroll={handleDepartmentDropdownScroll}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  addBtnFor="department"
                  onAdd={(data) => createDepartment({ name: data.newLabel })}
                  ref={departmentSelectRef}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Line manager" name="lineManagerIds">
                <InputDropDown
                  options={lineManagers?.users.nodes.map((item) => ({ label: item.name, value: item.id }))}
                  loading={lineManagersLoading}
                  placeholder="Please select"
                  optionLabelProp="label"
                  showSearch={false}
                  data-testid="lineManagerIds"
                  maxTagCount="responsive"
                  mode="multiple"
                  selectedValue={selectedLineManager}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[38, 0]}>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Work location" name="locationId">
                <InputAddDropDown
                  options={locations}
                  loading={locationsLoading}
                  data-testid="locationId"
                  onPopupScroll={handleLocationDropdownScroll}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Please select"
                  addBtnFor="work location"
                  onAdd={(data) => createLocation({ name: data.newLabel })}
                  ref={locationSelectRef}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Start date" name="startedAt">
                <DatePicker
                  className="w-100"
                  changeOnBlur
                  data-testid="startedAt"
                  format={DATE_FORMAT.datePickerAllowDate}
                  getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="End date (if applicable)" name="endedAt">
                <DatePicker
                  className="w-100"
                  changeOnBlur
                  data-testid="endedAt"
                  format={DATE_FORMAT.datePickerAllowDate}
                  getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[38, 0]} className="align-items-end">
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Probationary period (months)" name="probationaryPeriod">
                <InputNumber min={0} max={12} controls={false} className="w-100" data-testid="probationaryPeriod" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Annual leave" name="annualLeave" rules={[ALERTS.required]}>
                <InputDropDown
                  placeholder="Please select"
                  popupMatchSelectWidth={false}
                  data-testid="annualLeave"
                  options={enumValues(AnnualLeaveEnum).map((l) => ({
                    label: annualLeaveLabel[l].label,
                    value: l,
                  }))}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Number of holidays per annum" name="holidaysPerYear" rules={[ALERTS.required]}>
                <InputNumber min={0} controls={false} className="w-100" data-testid="holidaysPerYear" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[38, 0]} className="align-items-end">
            <Col md={24} lg={8} xl={7}>
              <Form.Item
                label="Holidays allowed to be carried over year to year"
                name="maxHolidaysFromLastYear"
                rules={[ALERTS.required]}
              >
                <InputNumber min={0} controls={false} className="w-100" data-testid="maxHolidaysFromLastYear" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Holidays brought forward from previous period" name="accumulatedCarriedHolidays">
                <InputNumber controls={false} className="w-100" data-testid="accumulatedCarriedHolidays" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Contract type" name="contractType">
                <InputDropDown
                  placeholder="Please select"
                  options={enumValues(ContractType).map((l) => ({ label: contractTypeLabel[l], value: l }))}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  data-testid="contractType"
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[38, 0]}>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Work phone number" name="phone" className="mb-0">
                <InputText data-testid="phone" />
              </Form.Item>
            </Col>
            <Col md={24} lg={8} xl={7}>
              <Form.Item label="Work email" name="email" rules={[ALERTS.required, ALERTS.email]} className="mb-0">
                <InputText type="email" data-testid="email" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Typography.Title className="border-bottom  pb-3 mt-5 mb-0" level={3}>
                Working days and hours
              </Typography.Title>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={24}>
              <Form.Item name="workingTime" colon={false} required={false} className="w-100 m-0">
                <Radio.Group data-testid="workingTime" onChange={handleWorkingTimeChange}>
                  <Space direction="horizontal">
                    {enumValues(WorkingTimeEnum).map((l) => (
                      <Radio value={l} key={l}>
                        <span className={`me-2  ${workingTime === l ? "fw-600" : "fw-500"} `}>
                          {workingTimeLabel[l]}
                        </span>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={24}>
              <WorkingConditionFormFields workingTime={workingTime} form={form} ref={workingConditionRef} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default JobDetails;
