import { gql } from "@apollo/client";
import { ERROR_FRAGMENT, PAGE_INFO_FRAGMENT } from "./common";

const DOCUMENT_FRAGMENT = gql`
  fragment documentFragment on Document {
    action {
      deadlineAt
      description
      id
    }
    actionCompletedCount
    actionPendingCount
    attachmentName
    attachmentUrl
    dateAdded
    id
    shareType
    sharedWithCount
    visibilityType
  }
`;

const DOCUMENT_USER_CONNECTION_FRAGMENT = gql`
  fragment documentUserConnection on Document {
    userDocumentConnections {
      userActionConnection {
        dateActioned
        id
      }
      dateAdded
      id
      user {
        id
        name
      }
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation createDocument(
    $actionDeadlineDate: ISO8601Date
    $actionDescription: String
    $attachment: Upload!
    $shareType: DocumentsShareTypeEnum
    $userIds: [ID!]
    $visibilityType: DocumentsVisibilityTypeEnum!
  ) {
    createDocument(
      actionDeadlineDate: $actionDeadlineDate
      actionDescription: $actionDescription
      attachment: $attachment
      shareType: $shareType
      userIds: $userIds
      visibilityType: $visibilityType
    ) {
      document {
        ...documentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const FETCH_DOCUMENTS = gql`
  query documents($first: Int, $after: String, $userId: ID, $notCompleted: Boolean) {
    documents(first: $first, after: $after, userId: $userId, notCompleted: $notCompleted) {
      edges {
        cursor
        node {
          id
        }
      }
      nodes {
        ...documentFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const DESTROY_DOCUMENT = gql`
  mutation destroyDocument($id: ID!) {
    destroyDocument(id: $id) {
      success
    }
  }
`;

export const FETCH_DOCUMENT_SHARED_DETAIL = gql`
  query fetchDocument($id: ID!) {
    fetchDocument(id: $id) {
      ...documentFragment
      ...documentUserConnection
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${DOCUMENT_USER_CONNECTION_FRAGMENT}
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($id: ID!, $shareType: DocumentsShareTypeEnum) {
    updateDocument(id: $id, shareType: $shareType) {
      document {
        ...documentFragment
        ...documentUserConnection
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
  ${DOCUMENT_USER_CONNECTION_FRAGMENT}
`;

export const FETCH_DOCUMENT_REVIEW = gql`
  query fetchDocumentReview($notActioned: Boolean) {
    documentReview: me {
      documents(notActioned: $notActioned) {
        id
        action {
          deadlineAt
          description
          id
        }
        attachmentName
        attachmentUrl
        dateAdded
        createdAt
      }
    }
  }
`;

export const FETCH_PROFILE_DOCUMENT = gql`
  query fetchProfileDocuments($notActioned: Boolean) {
    profileDocuments: me {
      documents(notActioned: $notActioned) {
        ...documentFragment
        ...documentUserConnection
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${DOCUMENT_USER_CONNECTION_FRAGMENT}
`;

export const SEND_DOCUMENT_REMINDER = gql`
  mutation sendDocumentReminder($documentId: ID!) {
    sendDocumentReminder(documentId: $documentId) {
      success
    }
  }
`;

export const COMPLETE_ACTIONS = gql`
  mutation createUserActionConnection($actionId: ID!) {
    createUserActionConnection(actionId: $actionId) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DELETE_ACCESS = gql`
  mutation destroyUserDocumentConnection($id: ID!) {
    destroyUserDocumentConnection(id: $id) {
      success
    }
  }
`;

export const CREATE_DOCUMENT_ACCESS = gql`
  mutation createUserDocumentConnection($documentId: ID!, $userIds: [ID!]!) {
    createUserDocumentConnection(documentId: $documentId, userIds: $userIds) {
      importedRecordsCount
    }
  }
`;

export default {
  UPLOAD_DOCUMENT,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENT_SHARED_DETAIL,
  UPDATE_DOCUMENT,
  FETCH_DOCUMENT_REVIEW,
  SEND_DOCUMENT_REMINDER,
  FETCH_PROFILE_DOCUMENT,
  COMPLETE_ACTIONS,
  CREATE_DOCUMENT_ACCESS,
};
