import dayjs from "dayjs";
import { OverViewPopoverList, OverViewPopoverListData } from "model/Leave";

const chartData = [
  { name: "overview", taken: 0, booked: 0, requested: 0, total: 0, remaining: 0, available: 0 },
  { name: "accrued", taken: 0, booked: 0, requested: 0, total: 0, remaining: 0, available: 0 },
];

const leaveCardRowColumnGap: Record<string, { row: string; col: string }> = {
  1: { row: "0px", col: "0px" },
  2: { row: "18px", col: "0px" },
  3: { row: "11px", col: "0px" },
  4: { row: "18px", col: "18px" },
  56: { row: "10px", col: "10px" },
  78: { row: "10px", col: "10px" },
  910: { row: "10px", col: "10px" },
  1112: { row: "5px", col: "5px" },
};

export const overViewPopoverListData = ({
  carryOverDays,
  holidaysPerYear,
  numberOfHolidayDayAdjustments,
}: OverViewPopoverList): OverViewPopoverListData[] => [
  {
    label: `${dayjs().year()} leave allowance`,
    value: (holidaysPerYear ?? 0).toFixed(2),
  },
  ...(carryOverDays
    ? [
        {
          label: "Carried from previous period",
          value: (carryOverDays ?? 0).toFixed(2),
        },
      ]
    : []),
  {
    label: "Ad hoc added",
    value: (numberOfHolidayDayAdjustments ?? 0).toFixed(2),
  },
];

export const LEAVE = {
  leaveCard1: "1",
  leaveCard2: "2",
  leaveCard3: "3",
  leaveCard4: "4",
  leaveCard56: "56",
  leaveCard78: "78",
  leaveCard910: "910",
  leaveCard1112: "1112",
  chartData,
  leaveCardRowColumnGap,
  overViewPopoverListData,
};

export default LEAVE;
