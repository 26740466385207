import { DatePicker, Form, Popover, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { PrimaryButton } from "components/Buttons";
import Icon from "components/Icon";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import { DateRangePicker } from "model/Insights";
import { useState } from "react";
import styled from "styled-components";

interface Props {
  dateFilter?: DateRangePicker;
  onSubmit: (value: DateRangePicker) => void;
}
const DateRangePickerFilter = ({ dateFilter, onSubmit }: Props) => {
  const [form] = useForm();
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);

  return (
    <Popover
      open={isOpenPopover}
      arrow={false}
      onOpenChange={(variables) => setIsOpenPopover(variables)}
      placement="bottomRight"
      overlayClassName="popover-date-range-picker "
      content={
        <Form
          form={form}
          layout="vertical"
          className="p-0"
          autoComplete="off"
          initialValues={{
            from: dayjs().subtract(1, "year"),
            to: dayjs(),
          }}
          onFinish={onSubmit}
        >
          <StyledDatePopoverContent>
            <Form.Item label="Start date" name="from">
              <DatePicker
                className="w-100"
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                changeOnBlur
                format={DATE_FORMAT.datePickerAllowDate}
              />
            </Form.Item>
            <Form.Item label="End date" name="to">
              <DatePicker
                className="w-100"
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                changeOnBlur
                format={DATE_FORMAT.datePickerAllowDate}
              />
            </Form.Item>

            <PrimaryButton htmlType="submit" onClick={() => setIsOpenPopover(false)}>
              Apply
            </PrimaryButton>
          </StyledDatePopoverContent>
        </Form>
      }
      trigger="click"
    >
      <StyledDateInput onClick={() => setIsOpenPopover(true)}>
        <Typography.Text>
          {` ${
            dateFilter?.from
              ? dayjs(dateFilter.from).format(DATE_FORMAT.shortMonthWithFullYear)
              : dayjs().subtract(1, "year").format(DATE_FORMAT.shortMonthWithFullYear)
          } 
           - 
          ${
            dateFilter?.to
              ? dayjs(dateFilter.to).format(DATE_FORMAT.shortMonthWithFullYear)
              : dayjs().format(DATE_FORMAT.shortMonthWithFullYear)
          }`}
        </Typography.Text>
        <Icon name="down" />
      </StyledDateInput>
    </Popover>
  );
};

export default DateRangePickerFilter;

const StyledDateInput = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const StyledDatePopoverContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
